export type ServerException = {
  httpStatus: number;
  key: string;
  message: string;
};

//=====
// Promise error are any... so we should be careful every time
//=====

export class ServerExceptionReader {

  static isBusinessNameAlreadyExistsException(error: any): boolean {
    if (!error) {
      return false;
    }

    return error?.key === 'BusinessDnsAlreadyExists';
  }

  static isBucketDnsAlreadyExistsException(error: any): boolean {
    if (!error) {
      return false;
    }

    return error?.key === 'BucketDnsAlreadyExists';
  }
}

