import { BucketDetails } from '../../domain/Bucket';
import styled from 'styled-components';
import { ApiSectionS3 } from './ApiSectionS3';

type BucketApiTabProps = {
  bucket: BucketDetails;
};

const TabContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 52px 20px 20px 20px;
`;

export const BucketApiTab = ({ bucket }: BucketApiTabProps) => {

  return (
        <TabContainer>
            <ApiSectionS3 bucket={bucket}/>
        </TabContainer>
  );
};
