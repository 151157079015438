import styled from 'styled-components';
import { AtdnText, Colors } from '@ataden/design-system';

type ValuePanelProps = {
  title: string;
  value: string;
  subTitle?: string;
  color?: string;
};

type ContainerProps = {
  color: string;
};

const Container = styled.div<ContainerProps>`
    padding: 32px 24px;
    border-radius: 12px;
    background-color: ${(props) => props.color};
`;

const Title = styled(AtdnText)`
    margin-bottom: 8px;
`;

const SubtitleLine = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: end;

    margin-top: 8px;
`;

export const ValuePanel = ({ title, value, color = Colors.Purple700, subTitle = ' ' }: ValuePanelProps) => {
  return (
        <Container color={color}>
            <Title color={Colors.White}>{title}</Title>
            <AtdnText color={Colors.White} scale={9}>{value}</AtdnText>
            <SubtitleLine>
                <AtdnText color={Colors.White} scale={1}>{subTitle}</AtdnText>
            </SubtitleLine>
        </Container>
  );
};
