import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { ValuePanel } from '../components/ValuePanel';
import { AtdnText, Colors, Loader } from '@ataden/design-system';
import { BucketSpaceChart } from '../components/BucketSpaceChart';
import { FormattedMessage, useIntl } from 'react-intl';
import { PageDescription, PageHeader, PageLayout } from '../../layout/PageLayout';
import { useGetBusinessDashboard } from '../repository/ProjectDashboardRepository';
import { useGetProjectIdFromPath } from '../../AuthenticatedRouter';
import { DashboardReport } from '../domain/DashboardReport';
import { byteTranslation, co2Translation, whTranslation } from '../../common/UnitTranslation';
import { dateDayAndMonthAndYearToDateString } from '../../common/DateUtils';


const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 100%;
`;

const DashboardRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 32px;
    width: 100%;
`;

export const DashboardPage = () => {
  const projectId = useGetProjectIdFromPath();
  const intl = useIntl();

  const getBusinessDashboard = useGetBusinessDashboard();

  const [dashboardReport, updateDashboardReport] = useState<DashboardReport>();
  const [loading, updateLoading] = useState<boolean>(false);

  useEffect(() => {
    updateLoading(true);
    getBusinessDashboard(projectId)
      .then(updateDashboardReport)
      .finally(() => {
        updateLoading(false);
      });
  }, [projectId]);

  if (loading || !dashboardReport) {
    return <Loader scale={10}/>;
  }

  const totalSpaceUsage = byteTranslation(dashboardReport.totalSize, intl);
  const electricalConsumption = whTranslation(dashboardReport.wh, intl);
  const co2Emission = co2Translation(dashboardReport.eqCO2, intl);


  const date = new Date();
  const sinceDate = dateDayAndMonthAndYearToDateString(1, date.getMonth(), date.getFullYear(), intl.formatMessage({ id: 'common.date' }));

  return (
        <PageLayout>
            <PageHeader>
                <AtdnText scale={7} color={Colors.Brown700}>
                    <FormattedMessage id="dashboard.title"/>
                </AtdnText>
                <PageDescription>
                    <FormattedMessage id="dashboard.description"/>
                </PageDescription>
            </PageHeader>

            <Container>
                <DashboardRow>
                    <ValuePanel title={intl.formatMessage({ id: 'dashboard.stats.total-space-usage.title' })}
                                value={totalSpaceUsage}/>
                    <ValuePanel title={intl.formatMessage({ id: 'dashboard.stats.electrical-consumption.title' })}
                                value={electricalConsumption}
                                color={Colors.Green700}
                                subTitle={intl.formatMessage({ id: 'dashboard.stats.electrical-consumption.since' }, { sinceDate: sinceDate })}/>
                    <ValuePanel
                        title={intl.formatMessage({ id: 'dashboard.stats.co2-emission.title' })}
                        value={co2Emission} color={Colors.Green700}
                        subTitle={intl.formatMessage({ id: 'dashboard.stats.co2-emission.since' }, { sinceDate: sinceDate })}/>
                </DashboardRow>
                <DashboardRow>
                    <BucketSpaceChart projectId={projectId} buckets={dashboardReport.buckets}
                                      totalSize={dashboardReport.totalSize}/>
                </DashboardRow>
            </Container>
        </PageLayout>
  );
};
