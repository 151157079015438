import React, { ReactElement } from 'react';
import { BannerNavigationMenu } from './BannerNavigationMenu';
import { LateralNavigationMenu } from './LateralNavigationMenu';
import styled from 'styled-components';

type NavigationMenuProps = {
  children: ReactElement;
};

const Page = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

export const NavigationMenu = ({ children }: NavigationMenuProps) => {

  return (
        <Page>
            <LateralNavigationMenu/>
            <Content>
                <BannerNavigationMenu/>
                {children}
            </Content>
        </Page>
  );
};
