import { AtdnButton } from '@ataden/design-system';
import { CardPaymentMethodCreationModal } from './CardPaymentMethodCreationModal';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

type CardPaymentMethodCreationProps = {
  normalButton?: boolean;
};

export const CardPaymentMethodCreation = ({ normalButton = true }: CardPaymentMethodCreationProps) => {

  const [showCreditCardCreationModal, updateCreditCardCreationModalVisibility] = useState<boolean>(false);

  const onCreditCardCreationBtn = () => {
    updateCreditCardCreationModalVisibility(true);
  };

  return (
        <>
            <AtdnButton size={normalButton ? 'normal' : 'big'} onClick={onCreditCardCreationBtn}>
                <FormattedMessage id="billing.payment-method.create.cta"/>
            </AtdnButton>
            {showCreditCardCreationModal &&
                <CardPaymentMethodCreationModal onClosed={() => updateCreditCardCreationModalVisibility(false)}/>}
        </>
  );
};
