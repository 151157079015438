import { AtdnButton, AtdnText, Colors, ExternalLinkIcon, spacing } from '@ataden/design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useApproveGeneralTerms } from '../repositories/UserRepository.hook';
import { RoutePath } from '../../AuthenticatedRouter';
import { useNavigate } from 'react-router-dom';
import { BigPictureLayout } from '../../layout/BigPictureLayout';
import { Panel } from '../../common/Panel';

const Title = styled(AtdnText)`
    text-align: left;
    margin-bottom: 24px;
`;

const Paragraph = styled(AtdnText)`
    text-align: left;
    margin-bottom: 36px;
`;

const Content = styled.div`

`;

const CtaLine = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
`;

const LinkLine = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    margin-bottom: ${spacing(4)};
    width: fit-content;

    cursor: pointer;
    margin-bottom: ${spacing(8)};
`;

const LinkText = styled(AtdnText)`
    text-decoration: underline;
`;

const LinkIconWrapper = styled.div`
    display: flex;
    width: 24px;
    padding-left: ${spacing(4)};
`;

export const UserLicenseApprobationPage = () => {

  const intl = useIntl();
  const approveGeneralTerms = useApproveGeneralTerms();
  const navigate = useNavigate();

  const generalTermsUrl = useMemo(() => {
    return intl.formatMessage({ id: 'user.general-term.url' });
  }, []);

  const onOpenGeneralTerms = () => {
    window.open(generalTermsUrl, '_blank');
  };

  const onGeneralTermAccepted = () => {
    approveGeneralTerms()
      .then(() => {

        navigate(RoutePath.dashboard);
      })
      .catch(() => {
        // TODO: handle error
      });
  };

  return (
        <BigPictureLayout>
            <Panel>
                <Title type="important" scale={7} color={Colors.Brown700}>
                    <FormattedMessage id="user.general-term.title"/>
                </Title>
                <Content>
                    <Paragraph type="normal" scale={4}>
                        <FormattedMessage id="user.general-term.description"/>
                    </Paragraph>
                    <LinkLine onClick={onOpenGeneralTerms}>
                        <LinkText scale={5}>{generalTermsUrl}</LinkText>
                        <LinkIconWrapper>
                            <ExternalLinkIcon/>
                        </LinkIconWrapper>
                    </LinkLine>
                    <CtaLine>
                        <AtdnButton size='big' onClick={onGeneralTermAccepted} type="Success"><FormattedMessage
                            id="user.general-term.btn.accept"/></AtdnButton>
                    </CtaLine>
                </Content>
            </Panel>
        </BigPictureLayout>
  );
};
