import { BucketDetails } from '../domain/Bucket';
import { AtdnText, Colors, spacing } from '@ataden/design-system';
import { byteTranslation } from '../../common/UnitTranslation';
import React from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';

type BucketHeaderProps = {
  bucket: BucketDetails;

  onBucketNameClicked: () => void;
};

const BucketTitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: ${spacing(5)};
    align-items: end;
`;

const BucketName = styled(AtdnText)`
    cursor: pointer
`;

const BucketSizeText = styled(AtdnText)`
    margin-left: ${spacing(6)};
`;

const BucketTotalSizeText = styled(AtdnText)`
    margin-left: ${spacing(6)};
    color: ${Colors.Gray500};
`;


export const BucketTitle = ({ bucket, onBucketNameClicked }: BucketHeaderProps) => {

  const intl = useIntl();

  if (bucket.size === bucket.sizeUploaded) {
    return (
            <BucketTitleContainer onClick={onBucketNameClicked}>
                <BucketName scale={7} color={Colors.Brown700}>{bucket.name}</BucketName>
                <BucketSizeText>{byteTranslation(bucket.size, intl)}</BucketSizeText>
            </BucketTitleContainer>
    );
  }

  return (
        <BucketTitleContainer onClick={onBucketNameClicked}>
            <BucketName scale={7} color={Colors.Brown700}>{bucket.name}</BucketName>
            <BucketSizeText>
                <FormattedMessage id="bucket.title.size.available"
                                  values={{ size: byteTranslation(bucket.sizeUploaded, intl) }}/>
            </BucketSizeText>
            <BucketTotalSizeText>
                <FormattedMessage id="bucket.title.size.total" values={{ size: byteTranslation(bucket.size, intl) }}/>
            </BucketTotalSizeText>
        </BucketTitleContainer>
  );
};
