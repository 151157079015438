import { AtdnButton, AtdnModal, AtdnText, Colors, spacing } from '@ataden/design-system';
import styled from 'styled-components';
import React from 'react';
import { PaymentProvider } from '../../config/PaymentProvider';
import { FormattedMessage } from 'react-intl';
import { Card } from '../../domain/Card';
import { useSelectCard } from '../../repositories/PaymentRepository.hook';

type CardDefaultSelectionModalProps = {
  businessId: string;
  card: Card;
  onSelection: () => void;
  onClosed: () => void;
};


const Title = styled(AtdnText)`
    color: ${Colors.Brown700};
    white-space: break-spaces;
    margin-bottom: 24px;
`;

const Label = styled(AtdnText)`
    color: ${Colors.Brown700};
    margin-bottom: 8px;
`;

const ActionContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: ${spacing(6)};
    gap: ${spacing(5)};
    width: 100%;
    justify-content: center;
`;


export const CardDefaultSelectionModal = ({
  businessId,
  card,
  onSelection,
  onClosed,
}: CardDefaultSelectionModalProps) => {

  const selectCard = useSelectCard(businessId);

  const onValidationRequested = () => {
    selectCard(card.id)
      .then(onSelection)
      .catch(() => {
        // TODO: manage error
      });
  };

  return (
        <PaymentProvider>

            <AtdnModal onCancel={onClosed}>
                <Title scale={5} type="important">
                    <FormattedMessage id="billing.payment-method.select.modal.title"/>
                </Title>
                <Label scale={3}>
                    <FormattedMessage id="billing.payment-method.select.modal.label"
                                      values={{ card: '\'XXXX XXXXX XXXX ' + card.last4 + '\'' }}/>
                </Label>

                <ActionContainer>
                    <AtdnButton onClick={onValidationRequested}>
                        <FormattedMessage id="billing.payment-method.select.modal.validate"/>
                    </AtdnButton>
                    <AtdnButton onClick={onClosed}>
                        <FormattedMessage id="billing.payment-method.select.modal.cancel"/>
                    </AtdnButton>
                </ActionContainer>
            </AtdnModal>
        </PaymentProvider>
  );
};
