import { Colors } from '@ataden/design-system';
import { DashboardBucket } from '../domain/DashboardReport';

export type BucketChart = {
  id: string;
  name: string;
  size: number;
  color: string;
};


export const OTHER_BUCKET_ID = '######';

const chartColors: string[] = ['#8067c7', '#67a9c7', '#67c795', '#b0c767', '#c7a867', Colors.Gray500];

const computeTotalBucketSize = (buckets: DashboardBucket[]): number => {
  let bucketCount = buckets.length;
  let size = 0;
  for (let i = 0; i < bucketCount; i++) {
    size += buckets[i].size;
  }
  return size;
};

export const generateBucketCharts = (buckets: DashboardBucket[], totalSize: number): BucketChart[] => {
  const result: BucketChart[] = [];

  buckets.slice(0, 5).forEach((bucket, idx) => {
    result.push({
      id: bucket.id,
      size: bucket.size,
      name: bucket.name,
      color: chartColors[idx],
    });
  });

  const computedTotalSize = computeTotalBucketSize(buckets);
  if (computeTotalBucketSize(buckets) < totalSize) {
    result.push({
      id: OTHER_BUCKET_ID,
      size: totalSize - computedTotalSize,
      name: 'other',
      color: chartColors[5],
    });
  }

  return result;
};
