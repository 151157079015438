import { atadenAxiosInstance, RestPage } from '../../common';
import { useProtectedGet, useProtectedPost, useProtectedPut } from '../../common/ProtectedRestCall.hook';
import { BusinessMember } from '../domain/BusinessMember';
import {
  useGetBusinessMembers,
  useGetCurrentBusinessInfo,
  useInitBusinessMembers,
  useInitCurrentBusiness,
} from '../BusinessStore';
import { BusinessInfo } from '../domain/BusinessInfo';
import { BillInformation } from '../../billing/domain/BillInformation';

type RestBusinessMember = {
  userId: string;
  businessId: string;
  role: string;
  createdDate: number;
};

type RestBusinessCreated = {
  id: string;
};

type RestBusinessValidation = {
  paymentCardValidated: boolean;
  billInformationValidated: boolean;
};


type RestBusinessInfo = {
  id: string;
  name: string;
  dns: string;
  status: 'CREATED' | 'READY';
  validation: RestBusinessValidation | undefined;
};

export type RestAddress = {
  line1: string;
  line2: string;
  zipCode: string;
  city: string;
};

export type RestBillInformation = {
  name: string;
  address: RestAddress;
  tax: string;
  contactName: string;
  contactPhone: string;
  contactEmail: string;
};

const convertRestBusinessMemberToDomain = (businessMember: RestBusinessMember): BusinessMember => {
  return {
    ...businessMember,
  };
};

const convertRestPageBusinessMemberToDomain = (rest: RestPage<RestBusinessMember>): BusinessMember[] => {
  return rest.items.map(convertRestBusinessMemberToDomain);
};

const convertRestBusinessInformationToDomain = (restBillInformation: RestBillInformation): BillInformation => {
  return {
    ...restBillInformation,
  };
};


export const useGetBusinessInfo = () => {
  const protectedGet = useProtectedGet<RestBusinessInfo, BusinessInfo>(atadenAxiosInstance, (restName) => {
    return { ...restName };
  });

  const getCurrentBusinessInfo = useGetCurrentBusinessInfo();

  return (businessId: string): Promise<BusinessInfo> => {
    return new Promise<BusinessInfo>((success, failure) => {

      const currentBusinessInfo = getCurrentBusinessInfo();
      if (currentBusinessInfo?.id === businessId) {
        success(currentBusinessInfo);
      }

      protectedGet('/api/v1/businesses/' + businessId + '/info')
        .then(businessName => {
          success(businessName);
        })
        .catch(failure);
    });
  };
};

export const useSetCurrentBusiness = () => {
  const initCurrentBusiness = useInitCurrentBusiness();

  return (businessInfo: BusinessInfo | undefined) => {
    initCurrentBusiness(businessInfo);
  };
};

export const useGetUserBusinessMembers = () => {
  const protectedGet = useProtectedGet<RestPage<RestBusinessMember>, BusinessMember[]>(atadenAxiosInstance, convertRestPageBusinessMemberToDomain);

  const initBusinessMembers = useInitBusinessMembers();
  const getBusinessMembers = useGetBusinessMembers();

  return (): Promise<BusinessMember[]> => {
    return new Promise<BusinessMember[]>((success, failure) => {

      const businessMembers = getBusinessMembers();
      if (businessMembers.length > 0) {
        success(businessMembers);
        return;
      }

      protectedGet('/api/v1/businesses/members')
        .then(members => {
          initBusinessMembers(members);
          success(members);
        })
        .catch(failure);
    });
  };
};

export const useCreateBusiness = () => {
  const protectedPost = useProtectedPost<RestBusinessCreated, string>(atadenAxiosInstance, (response) => response.id);

  return (businessName: string): Promise<string> => {
    return new Promise<string>((success, failure) => {

      protectedPost('/api/v1/businesses', { dns: businessName })
        .then(businessId => {
          success(businessId);
        })
        .catch(failure);
    });
  };
};

export const useGetBusinessBillInfo = () => {
  const protectedGet = useProtectedGet<RestBillInformation, BillInformation>(atadenAxiosInstance, convertRestBusinessInformationToDomain);

  return (businessId: string): Promise<BillInformation> => {
    return new Promise<BillInformation>((success, failure) => {

      protectedGet('/api/v1/businesses/' + businessId + '/bill-info')
        .then(billInformation => {
          success(billInformation);
        })
        .catch(failure);
    });
  };
};

export const usePutBusinessBillInfo = () => {
  const protectedPut = useProtectedPut<void, void>(atadenAxiosInstance, () => {
  });

  return (businessId: string, billInformation: BillInformation): Promise<void> => {
    return new Promise<void>((success, failure) => {

      protectedPut('/api/v1/businesses/' + businessId + '/bill-info', billInformation)
        .then(success)
        .catch(failure);
    });
  };
};
