import { useFetchBuckets } from '../repository/BucketRepository.hook';
import React, { useEffect, useState } from 'react';
import { Bucket } from '../domain/Bucket';
import { AtdnButton, Colors, Loader } from '@ataden/design-system';
import { FormattedMessage } from 'react-intl';
import { BucketList } from './BucketList';
import styled from 'styled-components';
import { BucketsToolbar } from './BucketsToolbar';
import { useNavigate } from 'react-router-dom';
import { getRouterBucketCreationPath } from '../../AuthenticatedRouter';

type BucketsPageContentProps = {
  projectId: string;
};

const EmptyContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin-top: 120px;
`;

export const BucketsPageContent = ({ projectId }: BucketsPageContentProps) => {
  const navigate = useNavigate();
  const fetchBuckets = useFetchBuckets();

  const [isLoading, updateLoadingState] = useState<boolean>(false);
  const [buckets, updateBuckets] = useState<Bucket[]>([]);


  useEffect(() => {
    updateLoadingState(true);
    fetchBuckets(projectId)
      .then(result => updateBuckets(result.items))
      .catch(() => {
        // TODO: handle errors
      })
      .finally(() => updateLoadingState(false));
  }, []);

  const onBucketCreation = () => {
    navigate(getRouterBucketCreationPath(projectId));
  };

  if (isLoading) {
    return (
            <EmptyContainer>
                <Loader scale={6} secondaryColor={Colors.Brown700}/>
            </EmptyContainer>
    );
  }

  if (buckets.length === 0) {
    return (
            <EmptyContainer>
                <AtdnButton size="big" onClick={onBucketCreation}>
                    <FormattedMessage id="buckets.create.first.cta"/>
                </AtdnButton>
            </EmptyContainer>
    );
  }

  return (
        <>
            <BucketsToolbar onBucketCreation={onBucketCreation}/>
            <BucketList projectId={projectId} buckets={buckets}/>
        </>
  );
};
