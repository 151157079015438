import { AtdnButton, AtdnInput, InputErrorText, Loader, ScreenSize } from '@ataden/design-system';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { BillInformation, EMPTY_BILL_INFORMATION } from '../../domain/BillInformation';
import { FormattedMessage, useIntl } from 'react-intl';
import { usePutBusinessBillInfo } from '../../../business/repositories/BusinessRepository.hook';
import { BusinessBillInfoUpdatedEvent } from '../../../business/domain/BusinessEventBus';
import { useBusinessEventBus } from '../../../business/Business.hook';

type BillInformationEditorComponentProps = {
  projectId: string;
  initialInformation: BillInformation;
  informationUpdated: (information: BillInformation) => void;
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const VerticalInputsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 18px;
    width: 100%;
    margin-bottom: 24px;
`;

const HorizontalInputsContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 18px;
    width: 100%;

    @media (max-width: ${ScreenSize.tablet}px) {
        flex-direction: column;
    }
`;

const BillInputContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;


const INFO_ADDRESS_LINE1_MISSING_ERROR: number = 1;
const INFO_ADDRESS_ZIPCODE_MISSING_ERROR: number = 2;
const INFO_ADDRESS_CITY_MISSING_ERROR: number = 4;
const INFO_CONTACT_NAME_MISSING_ERROR: number = 8;
const INFO_CONTACT_EMAIL_MISSING_ERROR: number = 16;
const INFO_CONTACT_PHONE_MISSING_ERROR: number = 32;


const validateString = (value: string): boolean => {
  return !(value === undefined || value.trim().length === 0);
};

export const BillInformationEditorComponent = ({
  projectId,
  initialInformation,
  informationUpdated,
}: BillInformationEditorComponentProps) => {

  const intl = useIntl();
  const updateBusinessBillInfo = usePutBusinessBillInfo();
  const businessEventBus = useBusinessEventBus();

  const [currentInformation, updateCurrentInformation] = useState<BillInformation>(EMPTY_BILL_INFORMATION);
  const [errors, updateErrors] = useState<number>(0);
  const [isProcessing, updateProcessing] = useState<boolean>(false);

  useEffect(() => {
    updateCurrentInformation(initialInformation);
  }, [projectId]);

  const cleanBeforeUpdate = (info: BillInformation): BillInformation => {
    return {
      name: info.name.trim(),
      address: {
        line1: info.address.line1.trim(),
        line2: info.address.line2.trim(),
        zipCode: info.address.zipCode.trim(),
        city: info.address.city.trim(),
      },
      tax: info.tax.trim(),
      contactName: info.contactName.trim(),
      contactEmail: info.contactEmail.trim(),
      contactPhone: info.contactPhone.trim(),
    };
  };

  const computeIfCanUpdate = (info: BillInformation) => {
    const billInfo = cleanBeforeUpdate(info);

    const initialObj = JSON.stringify(initialInformation, Object.keys(initialInformation).sort());
    const updateObj = JSON.stringify(billInfo, Object.keys(billInfo).sort());

    return initialObj !== updateObj;
  };

  const onValidate = (): boolean => {
    const errorValue = (validateString(currentInformation.address.line1) ? 0 : INFO_ADDRESS_LINE1_MISSING_ERROR)
            + (validateString(currentInformation.address.zipCode) ? 0 : INFO_ADDRESS_ZIPCODE_MISSING_ERROR)
            + (validateString(currentInformation.address.city) ? 0 : INFO_ADDRESS_CITY_MISSING_ERROR)
            + (validateString(currentInformation.contactName) ? 0 : INFO_CONTACT_NAME_MISSING_ERROR)
            + (validateString(currentInformation.contactEmail) ? 0 : INFO_CONTACT_EMAIL_MISSING_ERROR)
            + (validateString(currentInformation.contactPhone) ? 0 : INFO_CONTACT_PHONE_MISSING_ERROR);

    updateErrors(errorValue);
    return errorValue === 0;
  };

  const onBusinessNameUpdated = (value: string) => {
    const info: BillInformation = {
      ...currentInformation,
      name: value,
    };
    updateCurrentInformation(info);
  };

  const onAddressLine1Updated = (value: string) => {
    const info: BillInformation = {
      ...currentInformation,
      address: {
        ...currentInformation.address,
        line1: value,
      },
    };
    updateCurrentInformation(info);
  };

  const onAddressLine2Updated = (value: string) => {
    const info: BillInformation = {
      ...currentInformation,
      address: {
        ...currentInformation.address,
        line2: value,
      },
    };
    updateCurrentInformation(info);
  };

  const onAddressZipCodeUpdated = (value: string) => {
    const info: BillInformation = {
      ...currentInformation,
      address: {
        ...currentInformation.address,
        zipCode: value,
      },
    };
    updateCurrentInformation(info);
  };

  const onAddressCityUpdated = (value: string) => {
    const info: BillInformation = {
      ...currentInformation,
      address: {
        ...currentInformation.address,
        city: value,
      },
    };
    updateCurrentInformation(info);
  };

  const onTaxUpdated = (value: string) => {
    const info: BillInformation = {
      ...currentInformation,
      tax: value,
    };
    updateCurrentInformation(info);
  };

  const onContactNameUpdated = (value: string) => {
    const info: BillInformation = {
      ...currentInformation,
      contactName: value,
    };
    updateCurrentInformation(info);
  };

  const onContactEmailUpdated = (value: string) => {
    const info: BillInformation = {
      ...currentInformation,
      contactEmail: value,
    };
    updateCurrentInformation(info);
  };

  const onContactPhoneNumberUpdated = (value: string) => {
    const info: BillInformation = {
      ...currentInformation,
      contactPhone: value,
    };
    updateCurrentInformation(info);
  };

  const isUpdated = computeIfCanUpdate(currentInformation);

  const onUpdate = () => {
    const isValid = onValidate();
    if (isValid && isUpdated) {
      updateProcessing(true);
      updateBusinessBillInfo(projectId, currentInformation)
        .then(() => {
          businessEventBus.emit('onBillInfoUpdated', { businessId: projectId } as BusinessBillInfoUpdatedEvent);

          informationUpdated(currentInformation);
        })
        .finally(() => {
          updateProcessing(false);
        });
    }
  };

  return (
        <Container>
            <VerticalInputsContainer>
                <BillInputContainer>
                    <AtdnInput label={intl.formatMessage({ id: 'business.info.contact-name.input.label' })}
                               value={currentInformation.contactName}
                               onChange={(event) => onContactNameUpdated(event.target.value)} required/>
                    {(errors & INFO_CONTACT_NAME_MISSING_ERROR) === INFO_CONTACT_NAME_MISSING_ERROR && (
                        <InputErrorText>
                            <FormattedMessage id="business.info.contact-name.input.error.length"/>
                        </InputErrorText>
                    )}
                </BillInputContainer>

                <HorizontalInputsContainer>
                    <BillInputContainer>
                        <AtdnInput label={intl.formatMessage({ id: 'business.info.contact-email.input.label' })}
                                   value={currentInformation.contactEmail}
                                   onChange={(event) => onContactEmailUpdated(event.target.value)} required/>
                        {(errors & INFO_CONTACT_EMAIL_MISSING_ERROR) === INFO_CONTACT_EMAIL_MISSING_ERROR && (
                            <InputErrorText>
                                <FormattedMessage id="business.info.contact-email.input.error.length"/>
                            </InputErrorText>
                        )}
                    </BillInputContainer>
                    <BillInputContainer>
                        <AtdnInput label={intl.formatMessage({ id: 'business.info.contact-phone.input.label' })}
                                   value={currentInformation.contactPhone}
                                   onChange={(event) => onContactPhoneNumberUpdated(event.target.value)} required/>
                        {(errors & INFO_CONTACT_PHONE_MISSING_ERROR) === INFO_CONTACT_PHONE_MISSING_ERROR && (
                            <InputErrorText>
                                <FormattedMessage id="business.info.contact-phone.input.error.length"/>
                            </InputErrorText>
                        )}
                    </BillInputContainer>

                </HorizontalInputsContainer>

                <BillInputContainer>
                    <AtdnInput label={intl.formatMessage({ id: 'business.info.name.input.label' })}
                               value={currentInformation.name}
                               onChange={(event) => onBusinessNameUpdated(event.target.value)}/>
                </BillInputContainer>
                <BillInputContainer>
                    <AtdnInput label={intl.formatMessage({ id: 'business.info.tax.input.label' })}
                               value={currentInformation.tax}
                               onChange={(event) => onTaxUpdated(event.target.value)}/>
                </BillInputContainer>
                <BillInputContainer>
                    <AtdnInput label={intl.formatMessage({ id: 'business.info.address-line1.input.label' })}
                               value={currentInformation.address.line1}
                               onChange={(event) => onAddressLine1Updated(event.target.value)} required/>
                    {(errors & INFO_ADDRESS_LINE1_MISSING_ERROR) === INFO_ADDRESS_LINE1_MISSING_ERROR && (
                        <InputErrorText>
                            <FormattedMessage id="business.info.address-line1.input.error.length"/>
                        </InputErrorText>
                    )}
                </BillInputContainer>
                <BillInputContainer>
                    <AtdnInput label={intl.formatMessage({ id: 'business.info.address-line2.input.label' })}
                               value={currentInformation.address.line2}
                               onChange={(event) => onAddressLine2Updated(event.target.value)}/>
                </BillInputContainer>

                <HorizontalInputsContainer>
                    <BillInputContainer>
                        <AtdnInput label={intl.formatMessage({ id: 'business.info.address-zipcode.input.label' })}
                                   value={currentInformation.address.zipCode}
                                   onChange={(event) => onAddressZipCodeUpdated(event.target.value)} required/>
                        {(errors & INFO_ADDRESS_ZIPCODE_MISSING_ERROR) === INFO_ADDRESS_ZIPCODE_MISSING_ERROR && (
                            <InputErrorText>
                                <FormattedMessage id="business.info.address-zipcode.input.error.length"/>
                            </InputErrorText>
                        )}
                    </BillInputContainer>
                    <BillInputContainer>
                        <AtdnInput label={intl.formatMessage({ id: 'business.info.address-city.input.label' })}
                                   value={currentInformation.address.city}
                                   onChange={(event) => onAddressCityUpdated(event.target.value)} required/>
                        {(errors & INFO_ADDRESS_CITY_MISSING_ERROR) === INFO_ADDRESS_CITY_MISSING_ERROR && (
                            <InputErrorText>
                                <FormattedMessage id="business.info.address-city.input.error.length"/>
                            </InputErrorText>
                        )}
                    </BillInputContainer>
                </HorizontalInputsContainer>
            </VerticalInputsContainer>

            {isProcessing ? (
                <AtdnButton onClick={onUpdate} disabled>
                    <FormattedMessage id="business.info.updating.cta"/>
                    <Loader scale={1}/>
                </AtdnButton>
            ) : (
                <AtdnButton onClick={onUpdate} disabled={!isUpdated}>
                    <FormattedMessage id="business.info.update.cta"/>
                </AtdnButton>
            )}


        </Container>
  );
};

