import styled from 'styled-components';
import React from 'react';
import { useGetUserBusinessMembers } from '../../business/repositories/BusinessRepository.hook';
import { useNavigate } from 'react-router-dom';
import { getRouterHomePath, RoutePath } from '../../AuthenticatedRouter';
import { Colors, Loader } from '@ataden/design-system';
import { useGetCurrentUser } from '../../users/repositories/UserRepository.hook';
import { BusinessMember } from '../../business/domain/BusinessMember';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
`;

const LoaderContainer = styled.div`
    display: flex;
    width: 100%;
    align-content: center;
    justify-content: center;
`;

export const DashboardLoaderPage = () => {
  const getUserBusinessMembers = useGetUserBusinessMembers();
  const getCurrentUser = useGetCurrentUser();
  const navigate = useNavigate();

  const onLicenseNotApproved = () => {
    navigate(RoutePath.userLicenseApprobation);
  };

  const onNoProjectFound = () => {
    navigate(RoutePath.newProject);
  };

  const onProjectFound = (businessMember: BusinessMember[]) => {
    navigate(getRouterHomePath(businessMember[0].businessId));
  };

  const loadUserBusinessMembers = () => {
    getUserBusinessMembers()
      .then((businessMember) => {
        if (businessMember.length > 0) {
          onProjectFound(businessMember);
        } else {
          onNoProjectFound();
        }
      })
      .catch((err) => {
        console.log('err', err);
        // TODO: handle loading error
      });
  };

  getCurrentUser()
    .then(user => {
      if (!user.license) {
        onLicenseNotApproved();
      } else {
        loadUserBusinessMembers();
      }
    })
    .catch(() => {
      // TODO: handle loading error
    });


  return (
        <Container>
            <LoaderContainer>
                <Loader scale={6} secondaryColor={Colors.Brown700}/>
            </LoaderContainer>
        </Container>
  );
};
