import styled from 'styled-components';
import { MenuItem } from './MenuItem';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { getRouterHomePath, getRouterSettingsPath, useGetProjectIdFromPath } from '../../AuthenticatedRouter';
import { AtadenLogo, Colors, spacing } from '@ataden/design-system';
import { useIntl } from 'react-intl';
import { BucketMenuPart } from './BucketMenuPart';
import { OnboardingMenuPanel } from './OnboardingMenuPanel';
import React from 'react';

const MENU_WIDTH: number = 316;

const Container = styled.div`
    display: flex;
    flex-direction: column;

    width: ${MENU_WIDTH}px;
    max-width: ${MENU_WIDTH}px;
    height: 100%;

    background-color: ${Colors.Purple900};
    padding: 12px 12px 24px 12px;
`;


const LogoContainer = styled.div`
    padding: 0 0 24px 0;

    cursor: pointer;
`;

const ItemsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    padding-top: 24px;
    gap: 8px;
`;

const TopContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 8px;
`;

const BottomContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-bottom: 32px;
    gap: 8px;
    justify-content: end;
`;

const ExtraSpace = styled.div`
    height: ${spacing(5)};
`;


export const LateralNavigationMenu = () => {
  const logoutRedirection = process.env.REACT_APP__AUTH__LOGOUT!;

  const intl = useIntl();

  const projectId = useGetProjectIdFromPath();
  const navigate = useNavigate();
  const { logout } = useAuth0();

  const onLogOut = () => {
    logout({ logoutParams: { returnTo: logoutRedirection } });
  };

  const onSetting = () => {
    navigate(getRouterSettingsPath(projectId));
  };

  const onLogoClick = () => {
    navigate(getRouterHomePath(projectId));
  };

  const onDashboardClick = () => {
    navigate(getRouterHomePath(projectId));
  };

  return (
        <Container>
            <LogoContainer onClick={onLogoClick}>
                <AtadenLogo whiteMode={true} width="120px" height="48px"/>
            </LogoContainer>
            <ItemsContainer>
                <TopContainer>
                    <MenuItem title={intl.formatMessage({ id: 'menu.dashboard' })} selected={false}
                              onClick={onDashboardClick}/>
                    <BucketMenuPart projectId={projectId}/>
                </TopContainer>
                <OnboardingMenuPanel projectId={projectId}/>
                <BottomContainer>
                    <MenuItem title={intl.formatMessage({ id: 'menu.settings' })} selected={false} onClick={onSetting}/>
                    <ExtraSpace/>
                    <MenuItem title={intl.formatMessage({ id: 'menu.logout' })} selected={false} onClick={onLogOut}/>
                </BottomContainer>
            </ItemsContainer>
        </Container>
  );
};

