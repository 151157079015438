import { IntlShape } from 'react-intl';

const translate = (value: number, intl: IntlShape, translationKey: string, precision: number = 2): string => {
  return intl.formatMessage({ id: translationKey }, { value: +value.toFixed(precision) });
};

export const byteTranslation = (bytes: number, intl: IntlShape): string => {

  if (bytes < 1024) {
    return translate(bytes, intl, 'common.byte.byte');
  }

  if (bytes < 1024 * 1024) {
    return translate(bytes / 1024, intl, 'common.byte.kilo');
  }

  if (bytes < 1024 * 1024 * 1024) {
    return translate(bytes / (1024 * 1024), intl, 'common.byte.mega');
  }

  if (bytes < 1024 * 1024 * 1024 * 1024) {
    return translate(bytes / (1024 * 1024 * 1024), intl, 'common.byte.giga');
  }

  if (bytes < 1024 * 1024 * 1024 * 1024 * 1024) {
    return translate(bytes / (1024 * 1024 * 1024 * 1024), intl, 'common.byte.tera');
  }

  return '######';
};


export const co2Translation = (co2: number, intl: IntlShape): string => {

  if (co2 < 0.001) {
    return '< ' + translate(1, intl, 'common.mass.milli', 3);
  }

  if (co2 < 1) {
    return translate(co2, intl, 'common.mass.milli');
  }

  if (co2 < 1000) {
    return translate(co2, intl, 'common.mass.unit');
  }

  if (co2 < 1000 * 1000) {
    return translate(co2 / 1000, intl, 'common.mass.kilo');
  }

  if (co2 < 1000 * 1000 * 1000) {
    return translate(co2 / (1000 * 1000), intl, 'common.mass.ton');
  }

  return '######';
};


export const whTranslation = (power: number, intl: IntlShape): string => {

  if (power < 1) {
    return translate(power * 1000, intl, 'common.wh.milli');
  }

  if (power < 1000) {
    return translate(power, intl, 'common.wh.unit');
  }

  if (power < 1000 * 1000) {
    return translate(power / 1000, intl, 'common.wh.kilo');
  }

  if (power < 1000 * 1000 * 1000) {
    return translate(power / (1000 * 1000), intl, 'common.wh.mega');
  }

  if (power < 1000 * 1000 * 1000 * 1000) {
    return translate(power / (1000 * 1000 * 1000), intl, 'common.wh.giga');
  }

  return '######';
};
