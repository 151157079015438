import styled from 'styled-components';
import { AtdnText } from '@ataden/design-system';
import { useIntl } from 'react-intl';
import { byteTranslation } from '../../common/UnitTranslation';

type BucketSpaceProps = {
  label: string;
  value: number;
  color: string;

  onClick: () => void;
};

const Container = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;

    cursor: pointer;
`;

type ColorItemProps = {
  color: string;
};

const ColorItem = styled.div<ColorItemProps>`
    background-color: ${(props) => props.color};

    width: 20px;
    height: 20px;
`;

export const BucketSpace = ({ label, value, color, onClick }: BucketSpaceProps) => {
  const intl = useIntl();

  return (
        <Container onClick={onClick}>
            <ColorItem color={color}/>
            <AtdnText>{label} - {byteTranslation(value, intl)}</AtdnText>
        </Container>
  );
};
