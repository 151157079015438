import { ExplorerItemHeader } from './ExplorerItemHeader';
import { AtdnText, Colors } from '@ataden/design-system';
import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { FileUploadButton } from './FileUploadButton';

type EmptyFolderMessageProps = {
  bucketId: string;
  pagePath: string;
  onFileUploaded: (fileId: string) => void;
};

const EmptyFileContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 60px;
`;

export const EmptyFolderMessage = ({ bucketId, pagePath, onFileUploaded }: EmptyFolderMessageProps) => {
  const nothing = () => {

  };

  const isRootPath = pagePath === '/';

  return (
        <>
            <ExplorerItemHeader selectMode='NONE' onSelectAll={nothing} onUnselectAll={nothing}/>
            <EmptyFileContainer>
                <AtdnText color={Colors.Gray700}>
                    <FormattedMessage id="bucket.files.folder.empty"/>
                </AtdnText>

                {isRootPath && (
                    <FileUploadButton bucketId={bucketId} path={pagePath} buttonSize="big" canUpload={true}
                                      onFileUploading={nothing} onFileUploaded={onFileUploaded}/>
                )}
            </EmptyFileContainer>
        </>
  );
};
