import { PageDescription, PageHeader, PageLayout } from '../../layout/PageLayout';
import { FormattedMessage } from 'react-intl';
import { PaymentMethodComponent } from './components/PaymentMethodComponent';
import React from 'react';

export const PaymentMethodsPage = () => {
  return (
        <PageLayout>
            <PageHeader>
                <PageDescription>
                    <FormattedMessage id="billing.payment-method.description"/>
                </PageDescription>
            </PageHeader>

            <PaymentMethodComponent/>
        </PageLayout>

  );
};
