import { BucketDetails } from '../../domain/Bucket';
import styled from 'styled-components';
import { AtdnCopyButton, AtdnText, Colors } from '@ataden/design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { getRouterSettingsApiKeysPath, useGetProjectIdFromPath } from '../../../AuthenticatedRouter';
import { useGetCurrentBusinessInfo } from '../../../business/BusinessStore';

type S3SectionProps = {
  bucket: BucketDetails;
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const Title = styled(AtdnText)`
    margin-bottom: 36px;
`;

const Description = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 36px;
`;

const CopyKeyButton = styled(AtdnCopyButton)`
    margin-left: 8px;
`;

const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 48px;
`;

const DataSection = styled.div`
    display: flex;
    flex-direction: row;
`;

const DataTitle = styled(AtdnText)`

`;

const DataValue = styled(AtdnText)`
    margin-left: 4px;
`;

export const ApiSectionS3 = ({ bucket }: S3SectionProps) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const projectId = useGetProjectIdFromPath();

  const getCurrentBusinessInfo = useGetCurrentBusinessInfo();

  const endpointUrl = `${getCurrentBusinessInfo()?.dns}.${process.env.REACT_APP__S3_DOMAIN}`;
  const pathUrl = `/${bucket.dns}`;

  const onApiKeyPage = () => {
    navigate(getRouterSettingsApiKeysPath(projectId));
  };

  return (
        <Container>
            <Title scale={6} color={Colors.Brown700}>
                <FormattedMessage id="bucket.api.s3.title"/>
            </Title>
            <Description>
                <AtdnText>
                    <FormattedMessage id="bucket.api.s3.description"/>
                </AtdnText>
                <AtdnText>
                    <a
                        href={getRouterSettingsApiKeysPath(projectId)} onClick={onApiKeyPage}><FormattedMessage
                        id="bucket.api.s3.api-key"/>
                    </a>
                </AtdnText>
            </Description>

            <InfoContainer>
                <DataSection>
                    <DataTitle type="important" color={Colors.Brown700}>
                        <FormattedMessage id="bucket.api.s3.endpoint.title"/>:
                    </DataTitle>
                    <DataValue
                        color={Colors.Purple700}>{endpointUrl}</DataValue>
                    <CopyKeyButton textToCopy={endpointUrl}
                                   tooltipToCopy={intl.formatMessage({ id: 'bucket.api.s3.endpoint.copy' })}
                                   tooltipCopied={intl.formatMessage({ id: 'bucket.api.s3.endpoint.copied' })}/>
                </DataSection>
                <DataSection>
                    <DataTitle type="important" color={Colors.Brown700}>
                        <FormattedMessage id="bucket.api.s3.path.title"/>:
                    </DataTitle>
                    <DataValue
                        color={Colors.Purple700}>{pathUrl}</DataValue>
                    <CopyKeyButton textToCopy={pathUrl}
                                   tooltipToCopy={intl.formatMessage({ id: 'bucket.api.s3.path.copy' })}
                                   tooltipCopied={intl.formatMessage({ id: 'bucket.api.s3.path.copied' })}/>
                </DataSection>
                <DataSection>
                    <DataTitle type="important" color={Colors.Brown700}>
                        <FormattedMessage id="bucket.api.s3.port.title"/>:
                    </DataTitle>
                    <DataValue
                        color={Colors.Purple700}>443</DataValue>
                </DataSection>
                <DataSection>
                    <DataTitle type="important" color={Colors.Brown700}>
                        <FormattedMessage id="bucket.api.s3.signature.title"/>:
                    </DataTitle>
                    <DataValue
                        color={Colors.Purple700}>v4</DataValue>
                </DataSection>
            </InfoContainer>
        </Container>
  );
};
/*

  "bucket.api.s3.endpoint.title": "Endpoint",
  "bucket.api.s3.endpoint.copy": "Copier",
  "bucket.api.s3.endpoint.copied": "Copié",
  "bucket.api.s3.path.title": "Path",
  "bucket.api.s3.path.copy": "Copier",
  "bucket.api.s3.path.copied": "Copié",

 */
