import styled from 'styled-components';
import { AtdnText, Colors, RadioButtonIcon } from '@ataden/design-system';

type OnboardingElementProps = {
  label: string;
  finished: boolean;
  onClick?: () => void;
};

type ElementLabelProps = {
  finished: boolean;
};

const ElementLabel = styled(AtdnText)<ElementLabelProps>`
    color: ${Colors.White};
    text-decoration-line: ${(props) => props.finished ? 'line-through' : 'none'};
    cursor: ${(props) => props.finished ? 'default' : 'pointer'};
`;

const ElementContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

const ElementIconWrapper = styled.div`
    width: 20px;
    min-width: 20px;
    height: 20px;
    min-height: 20px;
    margin-right: 8px;
`;

export const OnboardingElement = ({ label, finished, onClick }: OnboardingElementProps) => {

  return (
        <ElementContainer onClick={() => onClick ? onClick() : {}}>
            <ElementIconWrapper>
                <RadioButtonIcon selected={finished} color={Colors.White}/>
            </ElementIconWrapper>
            <ElementLabel finished={finished}>{label}</ElementLabel>
        </ElementContainer>

  );
};
