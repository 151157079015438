import styled from 'styled-components';
import { AtdnText, Colors } from '@ataden/design-system';
import { OnboardingElement } from './OnboardingElement';
import { useNavigate } from 'react-router-dom';
import { getRouterSettingsBillingPath, getRouterSettingsPaymentMethodPath } from '../../AuthenticatedRouter';
import { FormattedMessage, useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { BusinessValidation } from '../../business/domain/BusinessInfo';
import { useGetBusinessInfo } from '../../business/repositories/BusinessRepository.hook';
import { useBusinessEventBus } from '../../business/Business.hook';

type OnboardingMenuPanelProps = {
  projectId: string;
};

const Container = styled.div`
    display: flex;
    flex-direction: column;

    background-color: ${Colors.Purple700};
    padding: 32px 24px;
    border-radius: 12px;

    gap: 8px;
`;

const Title = styled(AtdnText)`
    color: ${Colors.White};

    margin-bottom: 16px;
`;


export const OnboardingMenuPanel = ({ projectId }: OnboardingMenuPanelProps) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const getBusinessInfo = useGetBusinessInfo();
  const businessEventBus = useBusinessEventBus();

  const [businessValidation, updateBusinessValidation] = useState<BusinessValidation>();

  const onPaymentClick = () => {
    navigate(getRouterSettingsPaymentMethodPath(projectId));
  };

  const onBillClick = () => {
    navigate(getRouterSettingsBillingPath(projectId));
  };

  useEffect(() => {
    const onBillInfoUpdated = () => {
      updateBusinessValidation({
        ...businessValidation,
        billInformationValidated: true,
      } as BusinessValidation);
    };

    const onPaymentUpdated = () => {
      updateBusinessValidation({
        ...businessValidation,
        paymentCardValidated: true,
      } as BusinessValidation);
    };

    businessEventBus.on('onBillInfoUpdated', onBillInfoUpdated);
    businessEventBus.on('onPaymentUpdated', onPaymentUpdated);

    return () => {
      businessEventBus.off('onBillInfoUpdated', onBillInfoUpdated);
      businessEventBus.off('onPaymentUpdated', onPaymentUpdated);
    };
  }, [businessEventBus]);


  useEffect(() => {
    getBusinessInfo(projectId)
      .then((business) => {
        if (business.status !== 'CREATED') {
          updateBusinessValidation(undefined);
          return;
        }

        updateBusinessValidation(business.validation);
      });
  }, [projectId]);

  if (businessValidation === undefined) {
    return <></>;
  }

  return (
        <Container>
            <Title>
                <FormattedMessage id="menu.onboarding.title"/>
            </Title>
            <OnboardingElement label={intl.formatMessage({ id: 'menu.onboarding.task.create-project' })} finished={true}/>
            <OnboardingElement label={intl.formatMessage({ id: 'menu.onboarding.task.add-payment' })}
                               finished={businessValidation.paymentCardValidated}
                               onClick={onPaymentClick}/>
            <OnboardingElement label={intl.formatMessage({ id: 'menu.onboarding.task.add-bill-info' })}
                               finished={businessValidation.billInformationValidated}
                               onClick={onBillClick}/>
        </Container>
  );
};
