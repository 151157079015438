import { ResponsivePie } from '@nivo/pie';
import { Colors } from '@ataden/design-system';
import { byteTranslation } from '../../common/UnitTranslation';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { BucketChart } from './BucketChart';

type BucketPieChartComponentProps = {
  buckets: BucketChart[];

  onClick: (bucketId: string) => void;
};

const Container = styled.div`
    display: flex;
    padding: 32px 24px;

    height: 490px;
    width: 570px;
`;

export const BucketPieChartComponent = ({ buckets, onClick }: BucketPieChartComponentProps) => {
  const intl = useIntl();

  const data = buckets.map((bucket) => {
    return {
      id: bucket.name,
      internalId: bucket.id,
      value: bucket.size,
      color: bucket.color,
    };
  });

  const onDataClick = (clickInfo: any) => {
    onClick(clickInfo.data.internalId);
  };

  return (
        <Container>
            <ResponsivePie
                data={data}
                margin={{ top: 20, right: 80, bottom: 20, left: 80 }}
                colors={{
                  datum: 'data.color',
                }}
                innerRadius={0.5}
                padAngle={0.7}
                cornerRadius={3}
                activeOuterRadiusOffset={8}
                borderWidth={1}
                onClick={onDataClick}
                borderColor={{
                  from: 'color',
                  modifiers: [
                    [
                      'darker',
                      0.2,
                    ],
                  ],
                }}
                valueFormat={(value) => byteTranslation(value, intl)}
                arcLinkLabelsSkipAngle={10}
                arcLinkLabelsTextColor={Colors.Brown700}
                arcLinkLabelsThickness={2}
                arcLinkLabelsColor={{ from: 'color' }}
                arcLabelsSkipAngle={10}
                arcLabelsTextColor={Colors.White}
                defs={[
                  {
                    id: 'dots',
                    type: 'patternDots',
                    background: 'inherit',
                    color: 'rgba(255, 255, 255, 0.3)',
                    size: 4,
                    padding: 1,
                    stagger: true,
                  },
                  {
                    id: 'lines',
                    type: 'patternLines',
                    background: 'inherit',
                    color: 'rgba(255, 255, 255, 0.3)',
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 10,
                  },
                ]}
            />
        </Container>
  );
};

