import { ScreenSize } from '@ataden/design-system';
import { createContext, ReactElement, useContext, useEffect, useMemo } from 'react';
import { EventBus, eventbus } from '@ataden/event-bus';

type ScreenProviderProps = {
  children: ReactElement;
};

type ScreenDimension = {
  width: number;
  height: number;
};

type ScreenDimensionChanged = {
  width: number;
  height: number;
  isMobileSize: boolean;
};

type ScreenEventBusType = {
  'onSizeChanged': (event: ScreenDimensionChanged) => void;
};

type ScreenConfig = {
  screenEventBus: EventBus<ScreenEventBusType>
} & ScreenProviderProps;

export const ScreenContext = createContext<Partial<ScreenConfig>>({});

let gScreenWidth: number = window.innerWidth === undefined ? 0 : window.innerWidth;
let gScreenHeight: number = window.innerHeight === undefined ? 0 : window.innerHeight;

const Provider: React.FC<ScreenConfig> = (config: ScreenConfig) => {
  return <ScreenContext.Provider value={config}>{config.children}</ScreenContext.Provider>;
};


export const ScreenProvider = ({ children }: ScreenProviderProps) => {

  const screenEventBus = useMemo(() => {
    return eventbus<ScreenEventBusType>();
  }, []);


  useEffect(() => {
    if (typeof window === 'undefined' || window.innerHeight === undefined) return;

    const resize = () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);

      if (window.innerWidth !== gScreenWidth || window.innerHeight !== gScreenHeight) {
        gScreenWidth = window.innerWidth;
        gScreenHeight = window.innerHeight;

        screenEventBus.emit('onSizeChanged', {
          width: gScreenWidth,
          height: gScreenHeight,
          isMobileSize: gScreenWidth < ScreenSize.mobile,
        });
      }
    };

    resize();

    window.addEventListener('resize', resize);

    return () => {
      window.removeEventListener('resize', resize);
    };
  }, [screenEventBus]);

  return (
        <Provider screenEventBus={screenEventBus}>
            {children}
        </Provider>
  );
};

export const useGetScreenSize = () => {

  return (): ScreenDimension => {
    return {
      width: gScreenWidth,
      height: gScreenHeight,
    };
  };
};

export const useGetScreenEventBus = (): EventBus<ScreenEventBusType> => {
  return useContext(ScreenContext).screenEventBus!;
};


export const useGetIsMobile = () => {

  return (width: number): boolean => {
    return width < ScreenSize.mobile;
  };
};
export const useGetScreenIsMobile = () => {
  const getIsMobile = useGetIsMobile();

  return (): boolean => getIsMobile(gScreenWidth);
};

export const useGetIsTablet = () => {

  return (width: number): boolean => {
    return width < ScreenSize.tablet;
  };
};

export const useGetScreenIsTablet = () => {
  const getIsMobile = useGetIsTablet();

  return (): boolean => getIsMobile(gScreenWidth);
};

