import { useGetApiKeys } from '../repositories/ApiKeyRepository.hook';
import React, { useCallback, useEffect, useState } from 'react';
import { useGetProjectIdFromPath } from '../../AuthenticatedRouter';
import { ApiKey } from '../domain/ApiKey';
import { ApiKeyCreationModal } from '../components/ApiKeyCreationModal';
import styled from 'styled-components';
import { ApiKeyTable } from '../components/ApiKeyTable';
import { AtdnButton, Colors, Loader } from '@ataden/design-system';
import { PageDescription, PageHeader, PageLayout } from '../../layout/PageLayout';
import { FormattedMessage } from 'react-intl';

const ActionButtons = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: end;
    width: 100%;

    padding: 0 24px 16px 8px;
`;

const CenterContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
`;

export const ApiKeyPage = () => {

  const projectId = useGetProjectIdFromPath();
  const getApiKeys = useGetApiKeys();

  const [isLoading, updateLoadingState] = useState<boolean>(false);
  const [apiKeys, updateApiKeys] = useState<ApiKey[]>([]);
  const [modalVisibility, updateModalVisibility] = useState<boolean>();

  const loadApiKeys = useCallback(() => {
    updateLoadingState(true);
    getApiKeys(projectId)
      .then(updateApiKeys)
      .catch(() => {
        // TODO: handle errors
      })
      .finally(() => updateLoadingState(false));
  }, [projectId]);

  useEffect(() => {
    loadApiKeys();
  }, [loadApiKeys]);

  const onApiCreated = (accessId: string) => {
    updateModalVisibility(false);
    updateApiKeys([...apiKeys, {
      accessId: accessId,
      createdDate: new Date().getUTCDate(),
    }]);
  };

  const onModal = () => {
    updateModalVisibility(true);
  };

  const apiKeyDeleted = (apiKey: ApiKey) => {
    updateApiKeys(apiKeys.filter((key) => key.accessId !== apiKey.accessId));
  };

  if (isLoading) {
    return (
            <CenterContainer>
                <Loader scale={6} secondaryColor={Colors.Brown700}/>
            </CenterContainer>
    );
  }

  return (
        <PageLayout>
            <PageHeader>
                <PageDescription>
                    <FormattedMessage id="keys.description"/>
                </PageDescription>
            </PageHeader>

            <ActionButtons>
                {apiKeys.length > 0 && (
                    <AtdnButton onClick={onModal}>
                        <FormattedMessage id="keys.create.cta"/>
                    </AtdnButton>
                )}
            </ActionButtons>

            {apiKeys.length > 0 ? (
                <ApiKeyTable apiKeys={apiKeys} onApiKeyDeleted={apiKeyDeleted}/>
            ) : (
                <CenterContainer>
                    <AtdnButton onClick={onModal} size='big'>
                        <FormattedMessage id="keys.create.first.cta"/>
                    </AtdnButton>
                </CenterContainer>
            )}

            {modalVisibility && (
                <ApiKeyCreationModal onKeyCreated={onApiCreated}/>
            )}
        </PageLayout>
  );
};
