import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import React from 'react';
import { DashboardLayout } from './layout/DashboardLayout';
import { CreateBusinessPage } from './business/pages/CreateBusinessPage';
import { AuthenticatedLayout } from './layout/AuthenticatedLayout';
import { DashboardLoaderPage } from './dashboard/pages/DashboardLoaderPage';
import { ApiKeyPage } from './apikeys/pages/ApiKeyPage';
import { BucketsPage } from './buckets/pages/BucketsPage';
import { BucketCreationPage } from './buckets/pages/BucketCreationPage';
import { BucketPage } from './buckets/pages/BucketPage';
import { ProjectPage } from './layout/ProjectPage';
import { BillingPage } from './billing/pages/BillingPage';
import { UserLicenseApprobationPage } from './users/pages/UserLicenseApprobationPage';
import { SettingsLayout } from './settings/pages/SettingsLayout';
import { PaymentMethodsPage } from './billing/pages/PaymentMethodsPage';
import { SettingsGeneralPage } from './settings/pages/SettingsGeneralPage';
import { DashboardPage } from './dashboard/pages/DashboardPage';

export const RoutePath = {
  welcome: '/welcome',
  register: '/register',
  login: '/login',

  dashboard: '/dashboard',
  project: '/dashboard/:projectId',
  home: '/dashboard/:projectId/home',
  buckets: '/dashboard/:projectId/buckets',
  bucket: '/dashboard/:projectId/buckets/:bucketId',
  bucketApi: '/dashboard/:projectId/buckets/:bucketId/api',
  bucketSettings: '/dashboard/:projectId/buckets/:bucketId/settings',
  bucketCreate: '/dashboard/:projectId/buckets/create',
  billing: '/dashboard/:projectId/bills',
  settings: '/dashboard/:projectId/settings',
  settingGeneral: '/dashboard/:projectId/settings/general',
  settingApiKeys: '/dashboard/:projectId/settings/api-keys',
  settingPaymentMethods: '/dashboard/:projectId/settings/payment-methods',
  settingBillings: '/dashboard/:projectId/settings/billing',
  newProject: '/new-project',
  userLicenseApprobation: '/general-terms',
};

export const getRouterHomePath = (projectId: string) => RoutePath.home.replace(':projectId', projectId);
export const getRouterBucketsPath = (projectId: string) => RoutePath.buckets.replace(':projectId', projectId);
export const getRouterBucketPath = (projectId: string, bucketId: string, pageNumber?: number, pagePath?: string) => {
  const queryParam = pageNumber === undefined ? '' : '?page=' + pageNumber + '&path=' + pagePath;
  return RoutePath.bucket.replace(':projectId', projectId).replace(':bucketId', bucketId) + queryParam;
};
export const getRouterBucketApiPath = (projectId: string, bucketId: string) => {
  return RoutePath.bucketApi.replace(':projectId', projectId).replace(':bucketId', bucketId);
};
export const getRouterBucketSettingsPath = (projectId: string, bucketId: string) => {
  return RoutePath.bucketSettings.replace(':projectId', projectId).replace(':bucketId', bucketId);
};
export const getRouterBucketCreationPath = (projectId: string) => RoutePath.bucketCreate.replace(':projectId', projectId);
export const getRouterBillingPath = (projectId: string) => RoutePath.billing.replace(':projectId', projectId);
export const getRouterSettingsPath = (projectId: string) => RoutePath.settings.replace(':projectId', projectId);
export const getRouterSettingsGeneralPath = (projectId: string) => RoutePath.settingGeneral.replace(':projectId', projectId);
export const getRouterSettingsApiKeysPath = (projectId: string) => RoutePath.settingApiKeys.replace(':projectId', projectId);
export const getRouterSettingsPaymentMethodPath = (projectId: string) => RoutePath.settingPaymentMethods.replace(':projectId', projectId);
export const getRouterSettingsBillingPath = (projectId: string) => RoutePath.settingBillings.replace(':projectId', projectId);

export const AuthenticatedRouter = () => {
  return (
        <Routes>
            <Route element={<AuthenticatedLayout/>}>
                <Route index element={<Navigate to={RoutePath.dashboard} replace/>}/>
                <Route path={RoutePath.dashboard} element={<DashboardLoaderPage/>}/>
                <Route path={RoutePath.userLicenseApprobation} element={<UserLicenseApprobationPage/>}/>
                <Route path={RoutePath.newProject} element={<CreateBusinessPage/>}/>
                <Route element={<DashboardLayout/>}>
                    <Route index element={<Navigate to={RoutePath.home} replace/>}/>
                    <Route path={RoutePath.project} element={<ProjectPage/>}/>
                    <Route path={RoutePath.home} element={<DashboardPage/>}/>
                    <Route path={RoutePath.buckets} element={<BucketsPage/>}/>
                    <Route path={RoutePath.bucket} element={<BucketPage/>}/>
                    <Route path={RoutePath.bucketApi} element={<BucketPage/>}/>
                    <Route path={RoutePath.bucketSettings} element={<BucketPage/>}/>
                    <Route path={RoutePath.bucketCreate} element={<BucketCreationPage/>}/>
                    <Route path={RoutePath.billing} element={<BillingPage/>}/>

                    <Route element={<SettingsLayout/>}>
                        <Route path={RoutePath.settings} element={<ApiKeyPage/>}/>
                        <Route path={RoutePath.settingGeneral} element={<SettingsGeneralPage/>}/>
                        <Route path={RoutePath.settingApiKeys} element={<ApiKeyPage/>}/>
                        <Route path={RoutePath.settingPaymentMethods} element={<PaymentMethodsPage/>}/>
                        <Route path={RoutePath.settingBillings} element={<BillingPage/>}/>
                    </Route>
                </Route>
            </Route>
        </Routes>
  );
};

export const useGetProjectIdFromPath = (): string => {
  const { projectId } = useParams();

  return projectId!;
};
