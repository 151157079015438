import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useCreateBusiness } from '../repositories/BusinessRepository.hook';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from '../../AuthenticatedRouter';
import { ServerExceptionReader } from '../../common/ServerException';
import { AtdnButton, AtdnInput, AtdnText, Colors, InputErrorText, Loader } from '@ataden/design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import { BigPictureLayout } from '../../layout/BigPictureLayout';
import { Panel } from '../../common/Panel';
import {
  validateDnsNameCharacter,
  validateDnsNameHyphenPosition,
  validateDnsNameHyphenSequence,
  validateDnsNameLength,
} from '../../common/domain/DnsValidator';

const CustomPanel = styled(Panel)`
    max-width: 750px;
`;

const Title = styled(AtdnText)`
    text-align: left;
    margin-bottom: 24px;
`;

const Paragraph = styled(AtdnText)`
    text-align: left;
    margin-bottom: 48px;
`;

const Content = styled.div`

`;

const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 36px;
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;

const BUSINESS_NAME_LENGTH_ERROR: number = 1;
const BUSINESS_NAME_CHAR_ERROR: number = 2;
const BUSINESS_NAME_HYPHEN_POSITION_ERROR: number = 4;
const BUSINESS_NAME_HYPHEN_SEQUENCE_ERROR: number = 8;
const BUSINESS_NAME_ALREADY_EXIST_ERROR: number = 16;


export const CreateBusinessPage = () => {

  const [businessName, updateBusinessName] = useState<string>('');
  const [errors, updateErrors] = useState<number>(0);
  const [isCreatingBusiness, updateCreatingBusiness] = useState<boolean>(false);

  const intl = useIntl();
  const navigate = useNavigate();
  const createBusiness = useCreateBusiness();

  const onDnsUpdated = (inputName: string) => {
    const cleanedName = inputName.trim().toLowerCase();

    updateErrors((validateDnsNameLength(cleanedName) ? 0 : BUSINESS_NAME_LENGTH_ERROR)
            + (validateDnsNameCharacter(cleanedName) ? 0 : BUSINESS_NAME_CHAR_ERROR)
            + (validateDnsNameHyphenPosition(cleanedName) ? 0 : BUSINESS_NAME_HYPHEN_POSITION_ERROR)
            + (validateDnsNameHyphenSequence(cleanedName) ? 0 : BUSINESS_NAME_HYPHEN_SEQUENCE_ERROR));

    updateBusinessName(inputName.toLowerCase());
  };

  const onCreate = useMemo(() => {
    return () => {
      updateCreatingBusiness(true);
      createBusiness(businessName.trim())
        .then(() => {
          navigate(RoutePath.dashboard);
        })
        .catch(err => {
          if (ServerExceptionReader.isBusinessNameAlreadyExistsException(err)) {
            updateErrors(BUSINESS_NAME_ALREADY_EXIST_ERROR);
          }

          updateCreatingBusiness(false);
        });
    };
  }, [businessName, createBusiness, navigate]);

  const createButton = useMemo(() => {
    if (isCreatingBusiness) {
      return (<AtdnButton size='big' type="Success" disabled>
                <FormattedMessage id="onboarding.business.cta.creating"/>
                <Loader scale={2}/>
            </AtdnButton>);
    }

    return (
            <AtdnButton size='big' type="Success" onClick={onCreate} disabled={businessName.length === 0 || errors > 0}>
                <FormattedMessage id="onboarding.business.cta"/>
            </AtdnButton>);

  }, [isCreatingBusiness, businessName, onCreate]);

  return (
        <BigPictureLayout>
            <CustomPanel>
                <Title scale={7} color={Colors.Brown700}>
                    <FormattedMessage id="onboarding.business.title"/>
                </Title>

                <Content>
                    <Paragraph>
                        <FormattedMessage id="onboarding.business.description"/>
                    </Paragraph>
                    <InputWrapper>
                        <AtdnInput maxLength={200} label={intl.formatMessage({ id: 'onboarding.business.input' })}
                                   value={businessName} required
                                   onChange={(event) => onDnsUpdated(event.target.value)}/>
                        
                        {(errors & BUSINESS_NAME_LENGTH_ERROR) === BUSINESS_NAME_LENGTH_ERROR && (
                            <InputErrorText>
                                <FormattedMessage id="buckets.create.naming.input.error.length"/>
                            </InputErrorText>
                        )}
                        {(errors & BUSINESS_NAME_CHAR_ERROR) === BUSINESS_NAME_CHAR_ERROR && (
                            <InputErrorText>
                                <FormattedMessage id="buckets.create.naming.input.error.characters"/>
                            </InputErrorText>
                        )}
                        {(errors & BUSINESS_NAME_HYPHEN_POSITION_ERROR) === BUSINESS_NAME_HYPHEN_POSITION_ERROR && (
                            <InputErrorText>
                                <FormattedMessage id="buckets.create.naming.input.error.hyphens-position"/>
                            </InputErrorText>
                        )}
                        {(errors & BUSINESS_NAME_HYPHEN_SEQUENCE_ERROR) === BUSINESS_NAME_HYPHEN_SEQUENCE_ERROR && (
                            <InputErrorText>
                                <FormattedMessage id="buckets.create.naming.input.error.hyphens-sequence"/>
                            </InputErrorText>
                        )}
                        {(errors & BUSINESS_NAME_ALREADY_EXIST_ERROR) === BUSINESS_NAME_ALREADY_EXIST_ERROR && (
                            <InputErrorText>
                                <FormattedMessage id="buckets.create.naming.input.error.conflict"/>
                            </InputErrorText>
                        )}
                    </InputWrapper>
                    <ButtonWrapper>
                        {createButton}
                    </ButtonWrapper>

                </Content>
            </CustomPanel>
        </BigPictureLayout>
  );
};

/**


 */
