import { MenuItem } from './MenuItem';
import { useIntl } from 'react-intl';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ChevronIcon, Colors } from '@ataden/design-system';
import { getRouterBucketPath, getRouterBucketsPath } from '../../AuthenticatedRouter';
import { useNavigate } from 'react-router-dom';
import { useFetchBuckets } from '../../buckets/repository/BucketRepository.hook';
import { Bucket } from '../../buckets/domain/Bucket';
import { useBucketEventBus } from '../../buckets/Bucket.hook';

type BucketMenuPartProps = {
  projectId: string;
};

export const BucketMenuPart = ({ projectId }: BucketMenuPartProps) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const fetchBuckets = useFetchBuckets();
  const bucketEventBus = useBucketEventBus();

  const [buckets, updateBuckets] = useState<Bucket[]>([]);
  const [showMore, updateShowMoreVisibility] = useState<boolean>(false);

  const chevron = useMemo(() => {
    return <ChevronIcon direction="RIGHT" color={Colors.White}/>;
  }, []);

  const loadBuckets = useCallback(() => {
    fetchBuckets(projectId)
      .then(bucketPage => {
        if (bucketPage.totalElements > 5) {
          updateBuckets(bucketPage.items.slice(0, 5));
          updateShowMoreVisibility(true);
        } else {
          updateBuckets(bucketPage.items);
        }
      });
  }, [projectId]);

  useEffect(() => {
    loadBuckets();
  }, [loadBuckets]);

  useEffect(() => {
    const onBucketListChange = () => {
      loadBuckets();
    };

    bucketEventBus.on('onBucketCreated', onBucketListChange);
    bucketEventBus.on('onBucketDeleted', onBucketListChange);

    return () => {
      bucketEventBus.off('onBucketCreated', onBucketListChange);
      bucketEventBus.off('onBucketDeleted', onBucketListChange);
    };
  }, [bucketEventBus, loadBuckets]);

  const onBuckets = () => {
    navigate(getRouterBucketsPath(projectId));
  };

  const onBucket = (bucketId: string) => {
    navigate(getRouterBucketPath(projectId, bucketId));
  };

  return (
        <>
            <MenuItem title={intl.formatMessage({ id: 'menu.buckets' })} selected={false} onClick={onBuckets}/>
            {buckets.map((bucket) => (
                <MenuItem key={bucket.id} title={bucket.name} selected={false} isSubItem={true}
                          icon={chevron}
                          onClick={() => onBucket(bucket.id)}/>
            ))}
            {showMore && (
                <MenuItem title={intl.formatMessage({ id: 'menu.buckets.more' })} selected={false} isSubItem={true}
                          onClick={onBuckets}/>
            )}

        </>
  );
};
