import React from 'react';
import styled from 'styled-components';
import { NoInvoiceYet } from './EmptyInvoice';

const Container = styled.div`
    margin: 48px 0 96px 0;
`;

export const InvoicesComponent = () => {

  return (
        <Container>
            <NoInvoiceYet/>
        </Container>
  );
};

