import React, { useState } from 'react';
import styled from 'styled-components';
import { BucketDetails } from '../../domain/Bucket';
import { UpdateBucketNameOption } from './UpdateBucketNameOption';
import { UpdateBucketRetentionDayOption } from './UpdateBucketRetentionDayOption';
import { UpdateBucketShowDeletionOption } from './UpdateBucketShowDeletionOption';
import { DeleteBucketOption } from './DeleteBucketOption';
import { AtdnButton } from '@ataden/design-system';
import { FormattedMessage } from 'react-intl';

type BucketOptionsTabProps = {
  bucket: BucketDetails;
  onBucketUpdated: (bucket: BucketDetails) => void;
};


const TabContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 52px 20px 20px 20px;
    width: 100%;
`;

export const BucketOptionsTab = ({ bucket, onBucketUpdated }: BucketOptionsTabProps) => {

  const [showMoreOptions, updateShowMoreOptions] = useState<boolean>(false);

  return (

        <TabContainer>
            <UpdateBucketNameOption bucket={bucket} onBucketUpdated={onBucketUpdated}/>
            <UpdateBucketRetentionDayOption bucket={bucket} onBucketUpdated={onBucketUpdated}/>
            <UpdateBucketShowDeletionOption bucket={bucket} onBucketUpdated={onBucketUpdated}/>

            {showMoreOptions ? (
                <>
                    <DeleteBucketOption bucket={bucket}/>
                </>
            ) : (
                <AtdnButton onClick={() => updateShowMoreOptions(true)}><FormattedMessage
                    id="bucket.settings.show-more"/></AtdnButton>
            )}
        </TabContainer>


  );
};
