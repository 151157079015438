import { useProtectedGet } from '../../common/ProtectedRestCall.hook';
import { atadenAxiosInstance } from '../../common';
import { DashboardReport } from '../domain/DashboardReport';

export type RestDashboardBucket = {
  id: string;
  name: string;
  size: number;
};

export type RestDashboardReport = {
  spaceUsed: number;
  wh: number;
  eqCO2: number;
  buckets: RestDashboardBucket[];
};

const convertRestDashboardReportToDomain = (restDashboardReport: RestDashboardReport): DashboardReport => {
  return {
    totalSize: restDashboardReport.spaceUsed,
    wh: restDashboardReport.wh / 1000000,
    eqCO2: restDashboardReport.eqCO2,
    buckets: [...restDashboardReport.buckets].sort((a, b) => b.size - a.size),
  };
};

export const useGetBusinessDashboard = () => {
  const protectedGet = useProtectedGet<RestDashboardReport, DashboardReport>(atadenAxiosInstance, convertRestDashboardReportToDomain);

  return (businessId: string): Promise<DashboardReport> => {
    return new Promise<DashboardReport>((success, failure) => {
      protectedGet('/api/v1/businesses/' + businessId + '/dashboard')
        .then(dashboardReport => {
          success(dashboardReport);
        })
        .catch(failure);
    });
  };
};
