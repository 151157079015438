import React from 'react';
import { BillInformation } from '../../domain/BillInformation';
import { AtdnButton, AtdnText, ScreenSize } from '@ataden/design-system';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

type BillInformationDisplayComponentProps = {
  information: BillInformation;

  onEditRequest: () => void;
};

const VerticalGroups = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 18px;
`;

const HorizontalGroups = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 18px;

    @media (max-width: ${ScreenSize.tablet}px) {
        flex-direction: column;
    }
`;

const InformationGroup = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
`;

export const BillInformationDisplayComponent = ({ information, onEditRequest }: BillInformationDisplayComponentProps) => {
  return (
        <VerticalGroups>
            <HorizontalGroups>
                <InformationGroup>
                    <AtdnText type="important">
                        <FormattedMessage id="business.info.contact.title"/>
                    </AtdnText>
                    <AtdnText>
                        {information.contactName}
                    </AtdnText>
                    <AtdnText>
                        {information.contactEmail}
                    </AtdnText>
                    <AtdnText>
                        {information.contactPhone}
                    </AtdnText>
                </InformationGroup>
                <InformationGroup>
                    <AtdnText type="important">
                        <FormattedMessage id="business.info.company.title"/>
                    </AtdnText>
                    <AtdnText>
                        {information.name}
                    </AtdnText>
                    <AtdnText>
                        <FormattedMessage id="business.info.company.tax"/>: {information.tax}
                    </AtdnText>
                </InformationGroup>
            </HorizontalGroups>
            <HorizontalGroups>

                <InformationGroup>
                    <AtdnText type="important">
                        <FormattedMessage id="business.info.address.title"/>
                    </AtdnText>
                    <AtdnText>
                        {information.address.line1}
                    </AtdnText>
                    <AtdnText>
                        {information.address.line2}
                    </AtdnText>
                    <AtdnText>
                        {information.address.zipCode} {information.address.city}
                    </AtdnText>
                </InformationGroup>
                <InformationGroup>
                    <AtdnButton onClick={onEditRequest}><FormattedMessage id="business.info.edit.cta"/></AtdnButton>
                </InformationGroup>
            </HorizontalGroups>

        </VerticalGroups>
  );
};

