import styled from 'styled-components';
import { AtdnText, Colors } from '@ataden/design-system';
import { ReactNode } from 'react';


type MenuItemProps = {
  title: string;
  selected: boolean;
  isSubItem?: boolean;
  icon?: ReactNode;

  onClick: () => void;
};

type ContainerProps = {
  selected: boolean;
  isSubItem: boolean | undefined;
};

const Container = styled.div<ContainerProps>`
    display: flex;
    flex-direction: row;
    cursor: pointer;
    padding: 8px 20px 8px 24px;
    border-radius: 8px;
    background-color: ${(props) => props.selected ? Colors.Purple500 : undefined};

    align-items: center;
    margin-left: ${(props) => props.isSubItem ? '20px' : 0};

    &:hover {
        background-color: ${Colors.Purple300};
    }
`;

const ItemText = styled(AtdnText)`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

const IconWrapper = styled.div`
    display: flex;
    width: 12px;
    margin-right: 4px;
`;

export const MenuItem = ({ icon, title, isSubItem, selected, onClick }: MenuItemProps) => {

  return (
        <Container onClick={onClick} isSubItem={isSubItem} selected={selected}>
            {icon && (<IconWrapper>{icon}</IconWrapper>)}
            <ItemText scale={4} color={isSubItem ? Colors.Gray100 : Colors.White}>
                {title}
            </ItemText>
        </Container>
  );
};
