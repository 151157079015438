import { ApiKey } from '../domain/ApiKey';
import styled from 'styled-components';
import { dateNumberToDateString, timeNumberToTimeString } from '../../common/DateUtils';
import { ApiKeyDeletionModal } from './ApiKeyDeletionModal';
import { useState } from 'react';
import { AtdnText, Colors, DeleteIcon, spacing } from '@ataden/design-system';

type ApiKeyLineProps = {
  apiKey: ApiKey;

  onApiKeyDeleted: () => void;
};

const Container = styled.div`
    display: grid;
    grid-template-columns: minmax(0px, 1fr) 175px 50px;
    column-gap: 16px;

    width: 100%;
    padding: ${spacing(6)};
    margin-bottom: ${spacing(4)};
    border-bottom: 1px solid ${Colors.Gray100};
`;

const AccessIdItem = styled.div`
    font-family: 'Jetbrains', sans-serif;
    font-size: 18px;
    letter-spacing: 0;
`;

const CreationDateItem = styled.div`
    color: ${Colors.Brown700};
`;

const ActionItem = styled.div`
    text-align: end;
`;

const IconWrapper = styled.div`
    width: 21px;
    cursor: pointer;
`;

export const ApiKeyLine = ({ apiKey, onApiKeyDeleted }: ApiKeyLineProps) => {

  const [isModalVisible, updateModalVisibility] = useState<boolean>(false);

  const date = dateNumberToDateString(apiKey.createdDate, 'DD/MM/YYYY');
  const time = timeNumberToTimeString(apiKey.createdDate, 'hh:mm:ss');

  const onDeleteRequested = () => {
    updateModalVisibility(true);
  };

  return (
        <>
            <Container>
                <AccessIdItem>
                    {apiKey.accessId}
                </AccessIdItem>
                <CreationDateItem>
                    <AtdnText>
                        {date} {time}
                    </AtdnText>
                </CreationDateItem>
                <ActionItem>
                    <IconWrapper onClick={onDeleteRequested}>
                        <DeleteIcon/>
                    </IconWrapper>
                </ActionItem>
            </Container>
            {isModalVisible && (<ApiKeyDeletionModal apiKey={apiKey} onClosed={() => updateModalVisibility(false)}
                                                     onDeleted={onApiKeyDeleted}/>)}
        </>
  );
};
