import { BucketDetails } from '../../domain/Bucket';
import { AtdnButton, AtdnInput, AtdnText, Colors, Loader, pushToast, spacing } from '@ataden/design-system';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useUpdateBucket } from '../../repository/BucketRepository.hook';
import { FormattedMessage, useIntl } from 'react-intl';

type UpdateBucketRetentionDayOptionProps = {
  bucket: BucketDetails;
  onBucketUpdated: (bucket: BucketDetails) => void;
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 0 20px 0;
`;

const Header = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const HeaderContent = styled.div`
    display: flex;
    flex-direction: column;
`;

const Title = styled(AtdnText)`
    margin-bottom: 8px;
`;

const Description = styled(AtdnText)`
    margin-bottom: 8px;
`;

const ConfirmLine = styled.div`
    display: flex;
    justify-content: end;
    width: 100%;
`;

const Input = styled(AtdnInput)`
    margin-left: ${spacing(6)};
    max-width: 120px;
`;


export const UpdateBucketRetentionDayOption = ({ bucket, onBucketUpdated }: UpdateBucketRetentionDayOptionProps) => {

  const intl = useIntl();
  const updateBucket = useUpdateBucket();

  const [value, updateValue] = useState<number>(bucket.retentionDay);
  const [isProgressing, updateProgression] = useState<boolean>(false);

  const onInputValueChanged = (v: number) => {
    updateValue(Math.max(0, v));
  };

  const onConfirm = () => {

    updateProgression(true);
    updateBucket(bucket.id, {
      retentionDay: value,
    })
      .then(() => {
        onBucketUpdated({ ...bucket, retentionDay: value });

        pushToast({
          title: intl.formatMessage({ id: 'bucket.settings.retention.toast.success.title' }),
          description: intl.formatMessage({ id: 'bucket.settings.retention.toast.success.description' }),
          type: 'SUCCESS',
          durationInMs: 15000,
        });
      })
      .catch(() => {
        // TODO: handle error
      })
      .finally(() => {
        updateProgression(false);
      });

  };

  const onValueChanged = value !== bucket.retentionDay;

  return (
        <Container>
            <Header>
                <HeaderContent>
                    <Title type="important" color={Colors.Brown700}>
                        <FormattedMessage id="bucket.settings.retention.title"/>
                    </Title>
                    <Description>
                        <FormattedMessage id="bucket.settings.retention.description"/>
                    </Description>
                </HeaderContent>
                <Input label={intl.formatMessage({ id: 'bucket.settings.retention.input.label' })} type="number"
                       maxLength={5} value={value.toString()}
                       onChange={(evt) => onInputValueChanged(+evt.target.value)}/>
            </Header>
            <ConfirmLine>
                {onValueChanged && !isProgressing &&
                    <AtdnButton size="normal" type="Success" onClick={onConfirm}>
                        <FormattedMessage id="bucket.settings.retention.confirm.btn"/>
                    </AtdnButton>}

                {isProgressing &&
                    <AtdnButton size="normal" type="Success" disabled>
                        <FormattedMessage id="bucket.settings.retention.progress"/>
                        <Loader scale={1}/>
                    </AtdnButton>}
            </ConfirmLine>
        </Container>

  );
};
