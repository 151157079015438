import { createContext, ReactElement, useMemo } from 'react';
import { eventbus, EventBus } from '@ataden/event-bus';
import { BucketEventBusType } from './domain/BucketEventBus';

type BucketContextProviderProps = {
  children: ReactElement;
};

type BucketConfig = {
  bucketEventBus: EventBus<BucketEventBusType>;
} & BucketContextProviderProps;


export const BucketContext = createContext<Partial<BucketConfig>>({});

const Provider: React.FC<BucketConfig> = (config: BucketConfig) => {
  return <BucketContext.Provider value={config}>{config.children}</BucketContext.Provider>;
};

export const BucketContextProvider = ({ children }: BucketContextProviderProps) => {
  const bucketEventBus = useMemo(() => {
    return eventbus<BucketEventBusType>();
  }, []);

  return (
        <Provider
            bucketEventBus={bucketEventBus}
        >
            {children}
        </Provider>
  );
};
