import { PageDescription, PageHeader, PageLayout } from '../../layout/PageLayout';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { useEffect, useState } from 'react';
import { useGetProjectIdFromPath } from '../../AuthenticatedRouter';
import { AtdnCopyButton, AtdnText, Colors, Loader } from '@ataden/design-system';
import styled from 'styled-components';
import { useGetBusinessInfo } from '../../business/repositories/BusinessRepository.hook';
import { BusinessInfo } from '../../business/domain/BusinessInfo';

const ProjectInformationRow = styled.div`
    display: flex;
    flex-direction: row;
    gap: 16px;
    width: 100%;
`;

const ProjectInformationContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const SettingsGeneralPage = () => {
  const intl = useIntl();
  const projectId = useGetProjectIdFromPath();
  const getBusinessInfo = useGetBusinessInfo();

  const [business, updateBusiness] = useState<BusinessInfo>();

  useEffect(() => {
    getBusinessInfo(projectId)
      .then(updateBusiness);
  }, [projectId]);

  return (
        <PageLayout>
            <PageHeader>
                <PageDescription>
                    <FormattedMessage id="settings.general.description"/>
                </PageDescription>
            </PageHeader>

            {business ? (
                <ProjectInformationContainer>
                    <ProjectInformationRow>
                        <AtdnText type="important">
                            <FormattedMessage id="settings.general.project.name.label"/>
                        </AtdnText>
                        <AtdnText>{business.name}</AtdnText>
                        <AtdnCopyButton textToCopy={business.name}
                                        tooltipToCopy={intl.formatMessage({ id: 'settings.general.project.name.copy' })}
                                        tooltipCopied={intl.formatMessage({ id: 'settings.general.project.name.copied' })}/>
                    </ProjectInformationRow>
                    <ProjectInformationRow>
                        <AtdnText type="important">
                            <FormattedMessage id="settings.general.project.domain.label"/>
                        </AtdnText>
                        <AtdnText>{business.dns}</AtdnText>
                        <AtdnCopyButton textToCopy={business.dns}
                                        tooltipToCopy={intl.formatMessage({ id: 'settings.general.project.domain.copy' })}
                                        tooltipCopied={intl.formatMessage({ id: 'settings.general.project.domain.copied' })}/>
                    </ProjectInformationRow>
                    <ProjectInformationRow>
                        <AtdnText type="important">
                            <FormattedMessage id="settings.general.project.id.label"/>
                        </AtdnText>
                        <AtdnText>{projectId}</AtdnText>
                        <AtdnCopyButton textToCopy={projectId}
                                        tooltipToCopy={intl.formatMessage({ id: 'settings.general.project.id.copy' })}
                                        tooltipCopied={intl.formatMessage({ id: 'settings.general.project.id.copied' })}/>
                    </ProjectInformationRow>
                </ProjectInformationContainer>
            ) : (
                <Loader scale={6} secondaryColor={Colors.Brown700}/>
            )}
        </PageLayout>
  );
};
