import styled from 'styled-components';
import { Colors } from '@ataden/design-system';
import { BucketPieChartComponent } from './BucketPieChartComponent';
import { BucketSpace } from './BucketSpace';
import { DashboardBucket } from '../domain/DashboardReport';
import { useState } from 'react';
import { BucketChart, generateBucketCharts, OTHER_BUCKET_ID } from './BucketChart';
import { useNavigate } from 'react-router-dom';
import { getRouterBucketPath, getRouterBucketsPath } from '../../AuthenticatedRouter';

type BucketSpaceChartProps = {
  projectId: string;
  buckets: DashboardBucket[];
  totalSize: number;
};

const Container = styled.div`
    display: flex;
    flex-direction: row;

    border-radius: 12px;
    background-color: ${Colors.Gray000};
`;

const BucketsContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 20px;
    gap: 12px;
    height: 100%;
    justify-content: center;
`;

export const BucketSpaceChart = ({ projectId, buckets, totalSize }: BucketSpaceChartProps) => {

  const [bucketCharts] = useState<BucketChart[]>(generateBucketCharts(buckets, totalSize));
  const navigate = useNavigate();

  const onItemClick = (bucketId: string) => {
    if (bucketId === OTHER_BUCKET_ID) {
      navigate(getRouterBucketsPath(projectId));
      return;
    }

    navigate(getRouterBucketPath(projectId, bucketId));
  };

  return (
        <Container>
            <BucketPieChartComponent buckets={bucketCharts} onClick={onItemClick}/>
            <BucketsContainer>
                {bucketCharts.map((bucket) => (
                    <BucketSpace key={bucket.id} label={bucket.name} value={bucket.size} color={bucket.color}
                                 onClick={() => onItemClick(bucket.id)}/>
                ))}
            </BucketsContainer>
        </Container>
  );
};
