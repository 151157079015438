import { useEffect } from 'react';
import { useValidateCardPaymentSession } from '../../repositories/PaymentRepository.hook';
import { getRouterSettingsPaymentMethodPath } from '../../../AuthenticatedRouter';
import { useNavigate } from 'react-router-dom';
import { useBusinessEventBus } from '../../../business/Business.hook';
import { BusinessPaymentUpdatedEvent } from '../../../business/domain/BusinessEventBus';

type SyncStripePaymentMethodProps = {
  projectId: string;
  sessionId: string;
};

export const SyncStripePaymentMethod = ({ projectId, sessionId }: SyncStripePaymentMethodProps) => {
  const navigate = useNavigate();
  const validateCardPaymentSession = useValidateCardPaymentSession(projectId);
  const businessEventBus = useBusinessEventBus();

  useEffect(() => {
    validateCardPaymentSession(sessionId)
      .then(() => {
        // TODO: understand why we have to wait 1s to get the new card.
        setTimeout(() => {
          businessEventBus.emit('onPaymentUpdated', { businessId: projectId } as BusinessPaymentUpdatedEvent);
          navigate(getRouterSettingsPaymentMethodPath(projectId), {
            replace: true,
          });
        }, 1000);
      }).catch(() => {
        // TODO: handle error
      });

  }, [sessionId]);

  return <></>;
};
