import { useGetProjectIdFromPath } from '../../AuthenticatedRouter';
import { PageDescription, PageHeader, PageLayout } from '../../layout/PageLayout';
import { AtdnText, Colors } from '@ataden/design-system';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { BucketsPageContent } from '../components/BucketsPageContent';


export const BucketsPage = () => {
  const projectId = useGetProjectIdFromPath();

  return (
        <PageLayout>
            <PageHeader>
                <AtdnText scale={7} color={Colors.Brown700}>
                    <FormattedMessage id="buckets.title"/>
                </AtdnText>
                <PageDescription>
                    <FormattedMessage id="buckets.description"/>
                </PageDescription>
            </PageHeader>
            <BucketsPageContent projectId={projectId}/>
        </PageLayout>
  );
};
 
