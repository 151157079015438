import { AtdnButton, AtdnModal, AtdnText, Colors, spacing } from '@ataden/design-system';
import styled from 'styled-components';
import React from 'react';
import { FormattedMessage } from 'react-intl';

type DefaultCardDeletionModalProps = {
  onClosed: () => void;
};


const Title = styled(AtdnText)`
    color: ${Colors.Brown700};
    white-space: break-spaces;
    margin-bottom: 24px;
`;

const Label = styled(AtdnText)`
    color: ${Colors.Brown700};
    margin-bottom: 8px;
`;

const ActionContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: ${spacing(6)};
    gap: ${spacing(5)};
    width: 100%;
    justify-content: center;
`;

export const DefaultCardDeletionModal = ({ onClosed }: DefaultCardDeletionModalProps) => {

  return (
        <AtdnModal onCancel={onClosed}>
            <Title scale={5} type="important">
                <FormattedMessage id="billing.payment-method.default-deletion.modal.title"/>
            </Title>
            <Label scale={3}>
                <FormattedMessage id="billing.payment-method.default-deletion.modal.label"/>
            </Label>

            <ActionContainer>
                <AtdnButton onClick={onClosed}>
                    <FormattedMessage id="billing.payment-method.default-deletion.modal.cancel"/>
                </AtdnButton>
            </ActionContainer>
        </AtdnModal>
  );
};
