import { AtdnButton, spacing } from '@ataden/design-system';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

type BucketsToolbarProps = {
  onBucketCreation: () => void;
};

const Container = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: end;
    margin-bottom: ${spacing(5)};
    gap: ${spacing(2)};

`;

export const BucketsToolbar = ({ onBucketCreation }: BucketsToolbarProps) => {

  return (
        <Container>
            <AtdnButton size="normal" onClick={onBucketCreation}>
                <FormattedMessage id="buckets.create.cta"/>
            </AtdnButton>
        </Container>
  );

};
