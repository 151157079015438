import { FormattedMessage, useIntl } from 'react-intl';
import React, { useMemo, useState } from 'react';
import { useUploadFile } from '../repository/FileRepository.hook';
import { AtdnButton, AtdnUploadButton, ButtonSize, Loader, pushToast } from '@ataden/design-system';

type FileUploadButtonProps = {
  bucketId: string;
  buttonSize?: ButtonSize;

  path: string;
  canUpload: boolean;

  onFileUploading: (uploading: boolean) => void;
  onFileUploaded: (fileId: string) => void;
};

export const FileUploadButton = ({
  bucketId,
  buttonSize = 'normal',
  path,
  canUpload,
  onFileUploading,
  onFileUploaded,
}: FileUploadButtonProps) => {

  const intl = useIntl();

  const [isUploading, updateUploading] = useState<boolean>(false);
  const [uploadProgress, updateUploadProgress] = useState<number>(0);
  const uploadFile = useUploadFile();

  const onChange = (files: File[]) => {
    const file = files[0];

    updateUploading(true);
    onFileUploading(true);
    updateUploadProgress(0);

    uploadFile(bucketId, path + file.name, file, updateUploadProgress)
      .then((fileId) => {
        onFileUploaded(fileId);
        pushToast({
          title: intl.formatMessage({ id: 'bucket.files.upload.toast.success.title' }),
          description: intl.formatMessage({ id: 'bucket.files.upload.toast.success.description' }),
          type: 'SUCCESS',
        });
      })
      .catch(() => {
        pushToast({
          title: intl.formatMessage({ id: 'bucket.files.upload.toast.error.title' }),
          description: intl.formatMessage({ id: 'bucket.files.upload.toast.error.description' }),
          type: 'ERROR',
        });
      })
      .finally(() => {
        updateUploading(false);
        onFileUploading(false);
      },
      );
  };

  return useMemo(() => {
    if (isUploading) {
      return (<AtdnButton
                size={buttonSize} type="Primary" disabled>
                <FormattedMessage id="bucket.files.uploading" values={{ progress: Math.floor(uploadProgress * 100) }}/>
                <Loader scale={1}/>
            </AtdnButton>);
    }

    return (<AtdnUploadButton
            uniqueId="uploadBucketFile" onFilesUploaded={onChange} size={buttonSize} type="Primary"
            disabled={!canUpload}>
            <FormattedMessage id="bucket.files.upload.title"/>
        </AtdnUploadButton>);

  }, [buttonSize, isUploading, onChange, uploadProgress]);
};
