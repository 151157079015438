import {
  getRouterBucketApiPath,
  getRouterBucketPath,
  getRouterBucketSettingsPath,
  useGetProjectIdFromPath,
} from '../../AuthenticatedRouter';
import { PageLayout } from '../../layout/PageLayout';
import { useIntl } from 'react-intl';
import React, { useEffect, useState } from 'react';
import { matchRoutes, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { AtdnTab, AtdnTabs, spacing } from '@ataden/design-system';
import styled from 'styled-components';
import { Breadcrumb } from '../components/Breadcrumb';
import { BucketDetails } from '../domain/Bucket';
import { useGetBucket } from '../repository/BucketRepository.hook';
import { BucketFileTab } from '../components/BucketFileTab';
import { BucketOptionsTab } from '../components/settings/BucketOptionsTab';
import { BucketApiTab } from '../components/api/BucketApiTab';
import { BucketTitle } from '../components/BucketTitle';
import type { Location } from 'history';


enum BucketPageTabs {
  bucketFiles = 'bucket_files',
  bucketKeys = 'bucket_keys',
  bucketSettings = 'bucket_settings',
}

const BucketHeader = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 0 ${spacing(6)} 0;
`;


const matchPathWithTabs = (projectId: string, bucketId: string, currentLocation: Location): string => {
  const allSupportedRoutes = [
    { path: getRouterBucketPath(projectId, bucketId), tabId: BucketPageTabs.bucketFiles },
    { path: getRouterBucketApiPath(projectId, bucketId), tabId: BucketPageTabs.bucketKeys },
    { path: getRouterBucketSettingsPath(projectId, bucketId), tabId: BucketPageTabs.bucketSettings },
  ];
  const matches = matchRoutes(allSupportedRoutes, currentLocation);
  if (matches === null || matches.length === 0) {
    return BucketPageTabs.bucketFiles;
  }

  return (matches[0].route as any).tabId;
};


export const BucketPage = () => {

  const { bucketId } = useParams();
  const projectId = useGetProjectIdFromPath();
  const currentLocation = useLocation();

  const [searchParams] = useSearchParams();
  const [bucket, updateBucket] = useState<BucketDetails>();
  const [currentTab, updateCurrentTab] = useState<string>(matchPathWithTabs(projectId, bucketId!, currentLocation));

  const intl = useIntl();
  const navigate = useNavigate();
  const getBucket = useGetBucket();

  const pagePath = searchParams.get('path') || '/';

  const onTabSelected = (tabId: string) => {
    updateCurrentTab(tabId);

    if (tabId === BucketPageTabs.bucketFiles) {
      navigate(getRouterBucketPath(projectId, bucketId!));
      return;
    }

    if (tabId === BucketPageTabs.bucketKeys) {
      navigate(getRouterBucketApiPath(projectId, bucketId!));
      return;
    }

    if (tabId === BucketPageTabs.bucketSettings) {
      navigate(getRouterBucketSettingsPath(projectId, bucketId!));
      return;
    }
  };

  const onPageChange = (expectedPageNumber: number, expectedPagePath: string) => {
    navigate(getRouterBucketPath(projectId, bucketId!, expectedPageNumber, expectedPagePath));
  };

  useEffect(() => {
    if (!bucketId) {
      return;
    }

    getBucket(bucketId)
      .then(updateBucket)
      .catch(() => {
        // TODO: handle bucket fetch
      });
  }, [bucketId]);

  const onBucketNameClick = () => {
    onPageChange(1, '/');
    updateCurrentTab(BucketPageTabs.bucketFiles);
  };

  const onFolderSelection = (folderPath: string) => {
    onPageChange(1, folderPath);
  };

  return (
        <PageLayout>

            {bucket && (
                <>
                    <BucketHeader>
                        <BucketTitle bucket={bucket} onBucketNameClicked={onBucketNameClick}/>

                        {currentTab === BucketPageTabs.bucketFiles ? (
                            <Breadcrumb bucketName={bucket.name} path={pagePath} onPathSelection={onFolderSelection}/>
                        ) : (
                            <Breadcrumb bucketName={bucket.name} path='/' onPathSelection={onFolderSelection}/>
                        )}
                    </BucketHeader>

                    <AtdnTabs currentTab={currentTab} onTabSelected={onTabSelected}>
                        <AtdnTab id={BucketPageTabs.bucketFiles}
                                 title={intl.formatMessage({ id: 'bucket.tabs.files' })}>
                            <BucketFileTab projectId={projectId} bucket={bucket}/>
                        </AtdnTab>

                        <AtdnTab id={BucketPageTabs.bucketKeys} title={intl.formatMessage({ id: 'bucket.tabs.api' })}
                                 disabled={false}>
                            <BucketApiTab bucket={bucket}/>
                        </AtdnTab>

                        <AtdnTab id={BucketPageTabs.bucketSettings}
                                 title={intl.formatMessage({ id: 'bucket.tabs.settings' })}
                                 disabled={false}>
                            <BucketOptionsTab bucket={bucket} onBucketUpdated={updateBucket}/>
                        </AtdnTab>
                    </AtdnTabs>


                </>
            )}

        </PageLayout>);
};
