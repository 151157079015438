import { AtdnButton, AtdnInput, AtdnText, Colors, InputErrorText, Loader, spacing } from '@ataden/design-system';
import React, { useMemo, useState } from 'react';
import { getRouterBucketsPath, useGetProjectIdFromPath } from '../../AuthenticatedRouter';
import { PageLayout } from '../../layout/PageLayout';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { ServerExceptionReader } from '../../common/ServerException';
import { useCreateBucket } from '../repository/BucketRepository.hook';
import { useNavigate } from 'react-router-dom';
import {
  validateDnsNameCharacter,
  validateDnsNameHyphenPosition,
  validateDnsNameHyphenSequence,
  validateDnsNameLength,
} from '../../common/domain/DnsValidator';
import { useBucketEventBus } from '../Bucket.hook';

const PageTitle = styled(AtdnText)`
    margin: ${spacing(10)} 0;
    text-align: center;
    width: 100%;
`;

const SectionTitle = styled(AtdnText)`
    margin-bottom: ${spacing(6)};
`;

const SectionDescription = styled(AtdnText)`
    margin-bottom: ${spacing(4)};
`;

const ButtonContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: ${spacing(9)};
`;

const BUCKET_NAME_LENGTH_ERROR: number = 1;
const BUCKET_NAME_CHAR_ERROR: number = 2;
const BUCKET_NAME_HYPHEN_POSITION_ERROR: number = 4;
const BUCKET_NAME_HYPHEN_SEQUENCE_ERROR: number = 8;
const BUCKET_NAME_ALREADY_EXIST_ERROR: number = 16;


export const BucketCreationPage = () => {

  const intl = useIntl();
  const projectId = useGetProjectIdFromPath();
  const createBucket = useCreateBucket(projectId);
  const navigate = useNavigate();
  const bucketEventBus = useBucketEventBus();

  const [name, updateName] = useState<string>('');
  const [errors, updateErrors] = useState<number>(0);
  const [isCreatingBucket, updateCreatingBucket] = useState<boolean>(false);

  const onNameUpdated = (inputName: string) => {
    const cleanedName = inputName.trim().toLowerCase();

    updateErrors((validateDnsNameLength(cleanedName) ? 0 : BUCKET_NAME_LENGTH_ERROR)
            + (validateDnsNameCharacter(cleanedName) ? 0 : BUCKET_NAME_CHAR_ERROR)
            + (validateDnsNameHyphenPosition(cleanedName) ? 0 : BUCKET_NAME_HYPHEN_POSITION_ERROR)
            + (validateDnsNameHyphenSequence(cleanedName) ? 0 : BUCKET_NAME_HYPHEN_SEQUENCE_ERROR));

    updateName(inputName.toLowerCase());
  };
 
  const onCreate = useMemo(() => {
    return () => {
      updateCreatingBucket(true);
      createBucket(name.trim())
        .then((bucketId) => {
          bucketEventBus.emit('onBucketCreated', { bucketId });
          navigate(getRouterBucketsPath(projectId));
        })
        .catch(err => {
          if (ServerExceptionReader.isBucketDnsAlreadyExistsException(err)) {
            updateErrors(BUCKET_NAME_ALREADY_EXIST_ERROR);
          }

          updateCreatingBucket(false);
        });
    };
  }, [name, createBucket]);


  const createButton = useMemo(() => {
    if (isCreatingBucket) {
      return (<AtdnButton size='big' type="Success" disabled>
                <FormattedMessage id="buckets.create.cta.creating"/>
                <Loader scale={2}/>
            </AtdnButton>);
    }

    return (<AtdnButton size='big' type="Success" onClick={onCreate} disabled={name.length === 0 || errors > 0}>
            <FormattedMessage id="buckets.create.cta"/>
        </AtdnButton>);

  }, [isCreatingBucket, name, errors]);


  return (
        <PageLayout>
            <PageTitle type="important" color={Colors.Brown700} scale={6}>
                <FormattedMessage id="buckets.create.title"/>
            </PageTitle>

            <SectionTitle color={Colors.Brown700} scale={5}>
                <FormattedMessage id="buckets.create.naming.title"/>
            </SectionTitle>
            <SectionDescription>
                <FormattedMessage id="buckets.create.naming.description"/>
            </SectionDescription>
            <AtdnInput
                label={intl.formatMessage({ id: 'buckets.create.naming.input' })}
                value={name}
                maxLength={255}
                onChange={(evt) => onNameUpdated(evt.target.value)} required/>
            {(errors & BUCKET_NAME_LENGTH_ERROR) === BUCKET_NAME_LENGTH_ERROR && (
                <InputErrorText>
                    <FormattedMessage id="buckets.create.naming.input.error.length"/>
                </InputErrorText>
            )}
            {(errors & BUCKET_NAME_CHAR_ERROR) === BUCKET_NAME_CHAR_ERROR && (
                <InputErrorText>
                    <FormattedMessage id="buckets.create.naming.input.error.characters"/>
                </InputErrorText>
            )}
            {(errors & BUCKET_NAME_HYPHEN_POSITION_ERROR) === BUCKET_NAME_HYPHEN_POSITION_ERROR && (
                <InputErrorText>
                    <FormattedMessage id="buckets.create.naming.input.error.hyphens-position"/>
                </InputErrorText>
            )}
            {(errors & BUCKET_NAME_HYPHEN_SEQUENCE_ERROR) === BUCKET_NAME_HYPHEN_SEQUENCE_ERROR && (
                <InputErrorText>
                    <FormattedMessage id="buckets.create.naming.input.error.hyphens-sequence"/>
                </InputErrorText>
            )}
            {(errors & BUCKET_NAME_ALREADY_EXIST_ERROR) === BUCKET_NAME_ALREADY_EXIST_ERROR && (
                <InputErrorText>
                    <FormattedMessage id="buckets.create.naming.input.error.conflict"/>
                </InputErrorText>
            )}
            <ButtonContainer>
                {createButton}
            </ButtonContainer>
        </PageLayout>
  );
};
