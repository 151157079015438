import { LoginComponent } from './LoginComponent';
import { useAuth0 } from '@auth0/auth0-react';
import { BigPictureLayout } from '../../layout/BigPictureLayout';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

const randomTranslationKey = (): string => {
  const rnd = Math.random() * 4;
  if (rnd < 1) {
    return 'security';
  } else if (rnd < 2) {
    return 'pricing';
  }

  return 'ecology';
};

const solveTranslationKey = (hash: string): string => {
  if (!hash || hash.trim().length === 0) {
    return randomTranslationKey();
  }

  if (hash.localeCompare('#ecology') == 0) {
    return 'ecology';
  }

  if (hash.localeCompare('#security') == 0) {
    return 'security';
  }

  if (hash.localeCompare('#pricing') == 0) {
    return 'pricing';
  }

  return randomTranslationKey();
};

export const WelcomePage = () => {
  const intl = useIntl();
  const { hash } = useLocation();

  const { loginWithRedirect, isLoading, isAuthenticated } = useAuth0();

  const onLoginRequested = () => {
    loginWithRedirect();
  };

  const onRegisterRequested = () => {
    loginWithRedirect({
      authorizationParams: {
        screen_hint: 'signup',
      },
    });
  };

  const translationKey = solveTranslationKey(hash);
  const titleMessageId = 'welcome.' + translationKey + '.title';

  return (
        <BigPictureLayout title={intl.formatMessage({ id: titleMessageId })}>
            <LoginComponent isLoading={isLoading} isAuthenticated={isAuthenticated}
                            onLoginRequested={onLoginRequested} onRegisterRequested={onRegisterRequested}/>
        </BigPictureLayout>
  );
};
