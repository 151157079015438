import React from 'react';
import styled from 'styled-components';
import { AtdnText, Colors } from '@ataden/design-system';
import { FormattedMessage } from 'react-intl';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100px;
    justify-content: center;
    align-items: center;
`;

export const NoInvoiceYet = () => {

  return (
        <Container>
            <AtdnText color={Colors.Gray700}>
                <FormattedMessage id="billing.invoice.empty"/>
            </AtdnText>
        </Container>
  );
};
