import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useCreateApiKey } from '../repositories/ApiKeyRepository.hook';
import { useGetProjectIdFromPath } from '../../AuthenticatedRouter';
import { ApiKeyCreated } from '../domain/ApiKeyCreated';
import { AtdnButton, AtdnCopyButton, AtdnModal, Colors } from '@ataden/design-system';
import { FormattedMessage, useIntl } from 'react-intl';

type ApiKeyCreationModalProps = {
  onKeyCreated: (accessId: string) => void;
};

const Title = styled.div`
    font-size: 24px;
    font-weight: 700;
    line-height: 90%;
    color: ${Colors.Brown700};
    white-space: break-spaces;
    margin-bottom: 24px;
`;

const Label = styled.div`
    font-size: 16px;
    font-weight: 700;
    line-height: 90%;
    color: ${Colors.Brown700};
    margin-bottom: 8px;
    letter-spacing: -0.04em;
`;

const Info = styled.div`
    display: flex;
    margin-bottom: 16px;
    font-family: 'Jetbrains', sans-serif;
    font-size: 14px;
    letter-spacing: 0;
    background-color: ${Colors.Gray000};
    padding: 8px;
    color: ${Colors.Gray700};
    border-radius: 8px;
    justify-content: space-between;
    align-items: center;
    word-break: break-word;
`;

const InfoLoader = styled(Info)`
    position: relative;
    height: 36px;

    &::after {
        content: '';
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        background-image: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.5) 50%, transparent 100%);
        background-repeat: no-repeat;
        background-size: 75px 100%;
        background-position: -75px 0, center calc(100% + 75px);
        box-sizing: border-box;
        animation: animloader 1s linear infinite;
    }

    @keyframes animloader {
        to {
            background-position: calc(100% + 75px);
        }
    }
`;

const BtnLine = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const Warning = styled.div`
    font-size: 16px;
    padding: 16px;
    font-weight: 500;
    line-height: 90%;
    background-color: ${Colors.Yellow100};
    color: ${Colors.Yellow900};
    border-radius: 8px;
    margin-bottom: 48px;
`;


const CopyKeyButton = styled(AtdnCopyButton)`
    margin-left: 8px;
`;

export const ApiKeyCreationModal = ({ onKeyCreated }: ApiKeyCreationModalProps) => {

  const intl = useIntl();

  const projectId = useGetProjectIdFromPath();
  const createApiKey = useCreateApiKey();

  const [apiKeyCreated, updateApiKeyCreated] = useState<ApiKeyCreated>();

  useEffect(() => {
    createApiKey(projectId)
      .then((apiKey) => {
        updateApiKeyCreated(apiKey);
      })
      .catch(() => {
        // TODO: handle the error properly (an error toast ?)
      });
  }, []);

  return <AtdnModal>
        <div>
            <Title>
                <FormattedMessage id="keys.create.modal.title"/>
            </Title>
            <Label>
                <FormattedMessage id="keys.create.modal.accessId"/>:
            </Label>

            {apiKeyCreated ? (
                <Info>{apiKeyCreated.accessId}
                    <CopyKeyButton textToCopy={apiKeyCreated.accessId}
                                   tooltipToCopy={intl.formatMessage({ id: 'keys.create.modal.copy' })}
                                   tooltipCopied={intl.formatMessage({ id: 'keys.create.modal.copied' })}/>
                </Info>
            ) : (
                <InfoLoader/>
            )}

            <Label>
                <FormattedMessage id="keys.create.modal.secretId"/>:
            </Label>
            {apiKeyCreated ? (
                <Info>{apiKeyCreated.secretKey}
                    <CopyKeyButton textToCopy={apiKeyCreated.secretKey}
                                   tooltipToCopy={intl.formatMessage({ id: 'keys.create.modal.copy' })}
                                   tooltipCopied={intl.formatMessage({ id: 'keys.create.modal.copied' })}/>
                </Info>
            ) : (
                <InfoLoader/>
            )}

            <Warning>
                <FormattedMessage id="keys.create.modal.warning"/>
            </Warning>
            <BtnLine>
                <AtdnButton onClick={() => onKeyCreated(apiKeyCreated?.accessId!)}
                            disabled={apiKeyCreated === undefined}>
                    <FormattedMessage id="keys.create.modal.close"/>
                </AtdnButton>
            </BtnLine>
        </div>
    </AtdnModal>;
};
